import { List } from 'antd';
import ArrowRight from '../../admin/assets/images/lake_manager/RightArrow.png';
import React from 'react';
import '../assets/css/home_quick_links.css';

export default function HomeQuickLinks({ title, links }) {
  return (
    <List
      className={'home-quick-links br10'}
      itemLayout={'vertical'}
      header={
        <h3 className={'home-quick-links__title gSemiBold fs-26 mg-bottom-0'}>
          {title}
        </h3>
      }
      size={'large'}
      bordered
      dataSource={links}
      renderItem={(l, i) => (
        <a href={l.link} className={'home-quick-links__link gMedium fs-18'}>
          <List.Item
            key={l.link + i}
            className={'home-quick-links__option'}
            extra={
              <img
                src={ArrowRight}
                alt='Right arrow'
                className={'home-quick-links__icon fs-12'}
              />
            }
          >
            <span className={'gRegular'}>{l.title}</span>
          </List.Item>
        </a>
      )}
    />
  );
}
