import { SliderCardImage } from './SliderImages';
import { HashLink } from 'react-router-hash-link';

/**
 * A card to be used in BaseSlider
 * @param href {History.LocationDescriptor<H.LocationState> | ((location: History.Location<History.LocationState>) => History.LocationDescriptor<History.LocationState>)} the url to link to
 * @param image {{src: string|Image, alt: string}}
 * @param title {string} The main title
 * @param titleSize {number} font size in pixels for the title (corresponds to class names in library.css)
 * @param extraClassName {string} (optional) extra class names to be applied to the card
 * @param extraContentClassName {string} (optional) extra class names to be applied to slider card content wrapper
 * @param children {ReactNode} The main content of the card
 * @returns {JSX.Element}
 */
export default function HorizontalSliderImageCard({
  href,
  image,
  title,
  titleSize = 22,
  extraClassName = '',
  extraContentClassName = '',
  children,
}) {
  return (
    <div className={'slider-card horizontal ' + extraClassName}>
      <HashLink to={href}>
        <SliderCardImage src={image?.src} alt={image?.alt} />
        <div
          className={
            'slider-card__content pd-10 pd-left-20 pd-right-20' +
            extraContentClassName
          }
        >
          <p className={`fs-${titleSize} gBold sb-blue mg-bottom-8`}>
            {title
              ? title.length > 20
                ? title.slice(0, 20) + '...'
                : title
              : ''}
          </p>
          {children}
        </div>
      </HashLink>
    </div>
  );
}
