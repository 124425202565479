import React, { useEffect, useRef, useState } from 'react';
import { MenuOutlined } from '@ant-design/icons';
import { HorizontalMenuItem } from './HorizontalMenuItem';
import { getMenuItemByKey } from './menuOptions';
import UserIcon from '../../../../assets/images/Icons/SVG/UserIcon';
import { DefaultMenuDrawer } from './MenuDrawer';

export function FixedMobileMenu({ loading, isLoggedIn, handleLogOut }) {
  const mobileWrapperRef = useRef(null);
  const mobileMenuRef = useRef(null);

  const [drawerMenuOpen, setDrawerMenuOpen] = useState(false);
  const prevScrollPos = useRef(window.pageYOffset);

  useEffect(() => {
    const onScroll = () => {
      if (!mobileMenuRef?.current) {
        return;
      }
      const currentScrollPos = window.pageYOffset;
      if (prevScrollPos.current > currentScrollPos || currentScrollPos < 100) {
        mobileMenuRef.current.style.maxHeight = '5rem';
        mobileMenuRef.current.style.padding = '0.25rem 2rem';
      } else {
        mobileMenuRef.current.style.maxHeight = '0';
        mobileMenuRef.current.style.padding = '0';
      }
      prevScrollPos.current = currentScrollPos;
    };
    window.addEventListener('scroll', onScroll);
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <div id='main-menu-mobile-page-wrapper' ref={mobileWrapperRef}>
      <DefaultMenuDrawer
        isOpen={drawerMenuOpen}
        setIsOpen={setDrawerMenuOpen}
        loading={loading}
        isLoggedIn={isLoggedIn}
        handleLogOut={handleLogOut}
      />

      {/* Mobile menu at bottom of page */}
      <div id='main-menu-mobile' ref={mobileMenuRef}>
        <HorizontalMenuItem {...getMenuItemByKey('search')} />
        {!isLoggedIn ? (
          <HorizontalMenuItem
            key={'login'}
            onClick={() => setDrawerMenuOpen(true)}
            mobileIcon={<UserIcon />}
            shortTitle={'Log In'}
          />
        ) : (
          <HorizontalMenuItem {...getMenuItemByKey('profile')} />
        )}
        <HorizontalMenuItem
          key={'menu'}
          onClick={() => setDrawerMenuOpen(true)}
          mobileIcon={<MenuOutlined />}
          shortTitle={'Menu'}
        />
      </div>
    </div>
  );
}
