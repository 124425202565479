import React, { useEffect } from 'react';
import { Col, Layout, notification, Row } from 'antd';
import FishBlueIcon from '../../assets/images/img_blue_fish.png';
import EmailIcon from '../../assets/images/Icons/email.png';
import appStoreIcon from './assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg';
import googlePlayIcon from './assets/images/google-play-badge.png';
import facebookIcon from './assets/images/f_logo_RGB-Blue_58.png';
import instagramIcon from './assets/images/Instagram_Glyph_White.svg';
import twitterIcon from './assets/images/Twitter social icons - rounded square - blue.svg';
import youtubeIcon from './assets/images/yt_logo_rgb.png';
import talkSportLogo from '../../assets/images/Icons/talkSPORT_no_border_400.png';
import barclaysLogo from './assets/images/barclays-eagle-labs-logo_400.png';
import trustpilotMock from './assets/images/swimbooker-trustpilot-mockup.png';
import axios from 'axios';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { EnvironmentOutlined } from '@ant-design/icons';
import {
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
} from '../../AppConstants';

const { Footer: AntDFooter } = Layout;

function FooterLink({
  label,
  link = '',
  onClick = () => {},
  icon = undefined,
  externalLink = false,
  className = undefined,
  ...otherProps
}) {
  let linkContents = label;
  if (icon) {
    linkContents = (
      <>
        <div className='footer-contact-img'>{icon}</div>
        {label}
      </>
    );
  }

  let linkComponent;
  if (otherProps.searchFilter) {
    linkComponent = (
      <div
        className={className || 'cp gMedium sb-white'}
        onClick={otherProps.searchFilter}
      >
        {linkContents}
      </div>
    );
  } else if (!externalLink) {
    linkComponent = (
      <Link
        to={link}
        className={className || 'gMedium sb-white'}
        onClick={onClick}
      >
        {linkContents}
      </Link>
    );
  } else {
    linkComponent = (
      <a
        href={link}
        className={className || 'gMedium sb-white'}
        onClick={onClick}
      >
        {linkContents}
      </a>
    );
  }

  return (
    <div
      style={{ ...(icon ? { whiteSpace: 'nowrap' } : {}) }}
      className={'pd-top-10 mg-bottom-5'}
      {...otherProps}
    >
      {linkComponent}
    </div>
  );
}

export default function Footer({ isUserProfile = false }) {
  const history = useHistory();

  const checkUserLoginStatus = () => {
    const axios_config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };

    axios
      .get('auth', axios_config)
      .then((res) => {
        if (res.status === 200) {
          history.push(`/user/profile`);
        } else {
        }
      })
      .catch((err) => {
        if (err.response) {
          if (err.response.status === 401) {
            notification.error({
              message: <div className='gSemiBold'>There was an issue!</div>,
              style: { border: '1px #D34545 solid' },
              placement: window?.innerWidth <= 500 ? 'bottom' : 'topRight',
              className: 'gRegular br10',
              description: 'Please Login to continue',
            });
          }
        }
      });
  };

  const footerSections = [
    {
      title: 'Explore',
      links: [
        {
          label: 'Home',
          link: '/',
        },
        {
          label: 'Create Free Account',
          link: '/user/signup',
        },
        {
          label: 'Browse UK Fisheries',
          link: '/search',
        },
        {
          label: 'Browse European Fisheries',
          link: '/search?query=europe',
          searchFilter: () =>
            history.push(`/search`, {
              homeFilter: '0157a475-135b-462e-96da-da4342c1b10b',
            }),
        },
        {
          label: 'Browse Thailand Fisheries',
          link: '/search?query=thailand',
          searchFilter: () =>
            history.push(`/search`, {
              homeFilter: '7ab79dbc-94a6-4b65-a559-dd5a5b0419cd',
            }),
        },
        {
          label: 'Angling Social',
          link: '/articles',
        },
        {
          label: 'My Angler Profile',
          link: '#',
          onClick: checkUserLoginStatus,
        },
        {
          label: 'Fishery Management',
          link: '/admin/login',
        },
      ],
    },
    {
      title: 'Contact',
      span: {
        md: 7,
      },
      links: [
        {
          label: 'Get in Touch',
          link: '/contact',
        },
        {
          label: 'Anglers',
          link: '/contact/anglers',
        },
        {
          label: 'Fisheries',
          link: '/contact/fishery',
        },
        {
          label: 'Claim Fishery Profile',
          link: '/contact/fishery',
        },
        {
          label: 'support@swimbooker.com',
          className: 'gLight fs-14 sb-white',
          link: 'mailto:support@swimbooker.com',
          externalLink: true,
          icon: <img alt='Email Icon' src={EmailIcon} />,
        },
        {
          label: (
            <p>
              45 Fitzroy Street 4th Floor,
              <br />
              Silverstream House,
              <br />
              London, England,
              <br />
              W1T 6EB
            </p>
          ),
          link: 'https://goo.gl/maps/wbNwHmCqYcfEDGmj9',
          externalLink: true,
          className: 'gLight fs-14 sb-white display-inline',
          icon: <EnvironmentOutlined />,
        },
      ],
    },
    {
      title: 'Follow',
      links: [
        {
          label: 'Facebook',
          link: 'https://www.facebook.com/swimbookerUK',
          externalLink: true,
          icon: (
            <img
              alt='Facebook Icon'
              style={{
                background: '#1877F2',
                borderRadius: '3px',
              }}
              src={facebookIcon}
            />
          ),
        },
        {
          label: 'Instagram',
          link: 'https://www.instagram.com/swimbookeruk/',
          externalLink: true,
          icon: (
            <img
              alt='Instagram Icon'
              src={instagramIcon}
              style={{
                background:
                  'radial-gradient(circle at 30% 107%, #FFD600 0%, #FF7A00 5%, #FF0069 45%,#D300C5 60%,#7638FA 90%)',
                padding: '3px',
                borderRadius: '4px',
              }}
            />
          ),
        },
        {
          label: 'Twitter',
          link: 'https://twitter.com/swimbooker',
          externalLink: true,
          icon: <img alt='Twitter Icon' src={twitterIcon} />,
        },
        {
          label: 'YouTube',
          link: 'https://youtube.com/@swimbookerUK',
          externalLink: true,
          icon: (
            <img
              alt='YouTube Icon'
              src={youtubeIcon}
              style={{ height: 'unset', width: '28px' }}
            />
          ),
        },
      ],
    },
    {
      title: 'Legal',
      span: {
        md: 5,
        lg: 4,
      },
      links: [
        {
          label: 'Terms & Conditions',
          externalLink: true,
          link: TERMS_AND_CONDITIONS_URL,
        },
        {
          label: 'Privacy Policy',
          externalLink: true,
          link: PRIVACY_POLICY_URL,
        },
      ],
    },
  ];

  const externalLogos = (
    <div className={'sb-footer__external_logos_container'}>
      <a
        href={'https://fb.watch/fHSJUPIuhK/'}
        className={'sb-footer__external_logo'}
        style={{ background: 'black' }}
      >
        <img src={talkSportLogo} alt={'talkSport Logo'} />
      </a>
      <a
        className={'sb-footer__external_logo'}
        href='https://labs.uk.barclays/'
      >
        <img src={barclaysLogo} alt='Barclays Eagle Labs Logo' />
      </a>

      <div
        class='trustpilot-widget'
        data-locale='en-US'
        data-template-id='53aa8807dec7e10d38f59f32'
        data-businessunit-id='61657394fa7737001e35bc8a'
        data-style-height='150px'
        data-style-width='100%'
        data-theme='dark'
      >
        <a
          href='https://uk.trustpilot.com/review/www.swimbooker.com'
          target='_blank'
          rel='noopener'
        >
          Trustpilot
        </a>
      </div>
    </div>
  );

  useEffect(() => {
    // Wait for the Trustpilot script to load and then initialize the widget
    const loadTrustpilotWidget = () => {
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(
          document.getElementsByClassName('trustpilot-widget')[0],
          true
        );
      }
    };

    // Check if Trustpilot script is loaded
    if (window.Trustpilot) {
      loadTrustpilotWidget();
    } else {
      // If not loaded, add an event listener to initialize once it loads
      const trustpilotScript = document.createElement('script');
      trustpilotScript.src =
        '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
      trustpilotScript.async = true;
      trustpilotScript.onload = loadTrustpilotWidget;
      document.body.appendChild(trustpilotScript);
    }
  }, []);

  return (
    <AntDFooter className='sb-footer sb-blue-bg-800 main_container_padding'>
      <div id='sb-footer' className={'main_container_padding__content'}>
        <Row>
          <Col span={24}>
            <p className='mg-0'>
              <img
                alt='Swimbooker Logo: Icon of a fish on a blue background'
                width={40}
                src={FishBlueIcon}
              />
            </p>
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col
            className='gutter-row mg-top-10 mg-bottom-30 sb-footer__logos_container'
            xs={24}
            sm={24}
            lg={isUserProfile ? 24 : 5}
          >
            <h2 className='gSemiBold sb-white'>swimbooker.com</h2>
            <h3 className='gSemiBold fs-20 sb-white'>
              Less Hassle. More Fishing.
            </h3>
            <b className='gSemiBold sb-white mg-bottom-10 sb-footer__hide_on_large'>
              Download Our Mobile App Today!
            </b>
            <div
              className={
                'sb-footer__app_links_container sb-footer__hide_on_large'
              }
            >
              <a
                href={'https://apps.apple.com/gb/app/swimbooker/id1586855906'}
                className={'sb-footer__app_link'}
              >
                <img src={appStoreIcon} alt={'Download on the App Store'} />
              </a>
              <a
                className={'sb-footer__app_link'}
                href='https://play.google.com/store/apps/details?id=com.app.swimbookerapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
              >
                <img src={googlePlayIcon} alt='Get it on Google Play' />
              </a>
            </div>
            <div className={'sb-footer__hide_on_mobile_tablet'}>
              {externalLogos}
            </div>
          </Col>

          {footerSections.map((s) => (
            <Col
              key={s.title}
              className='gutter-row col2 mg-top-10 mg-bottom-20'
              xs={24}
              sm={12}
              md={isUserProfile ? 12 : 6}
              lg={isUserProfile ? 12 : 5}
              {...s.span}
            >
              <h2 className='gSemiBold sb-white mg-bottom-5'>{s.title}</h2>
              {s.links.map((linkProps) => (
                <FooterLink
                  key={s.title + '_' + linkProps.label}
                  {...linkProps}
                />
              ))}
              {s?.extra ? (
                <Row className={'pd-top-10 mg-bottom-5'}>{s.extra}</Row>
              ) : (
                ''
              )}
            </Col>
          ))}
        </Row>
        <Row className='mg-bottom-10'>
          <Col xs={24} lg={0}>
            {externalLogos}
          </Col>
          <Col span={24}>
            <p className='sb-copyright gSemiBold'>©SWIMBOOKER LIMITED</p>
          </Col>
        </Row>
      </div>
    </AntDFooter>
  );
}
