import * as React from 'react';

const SearchIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 39.96 40' {...props}>
    <defs>
      <style>
        {
          '.cls-1{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:4px}'
        }
      </style>
    </defs>
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Icons'>
        <path
          stroke={'currentColor'}
          className='cls-1'
          d='M29.27 15.64A13.63 13.63 0 1 1 15.64 2a13.63 13.63 0 0 1 13.63 13.64ZM37.96 38l-9.88-9.89'
        />
      </g>
    </g>
  </svg>
);

export default SearchIcon;
