import React from 'react';
import { Button } from 'antd';
import { useHistory } from 'react-router';
import { MENU } from '../../../../../AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import { useGlobalState } from './GlobalState';
import checked from '../../../../common/assets/images/checked.svg';
const { TOGGLE_CATCH_REPORT } = MENU;
function Success() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [catchInfo, setCatchInfo] = useGlobalState('catchInfo');
  return (
    <div className='bg-white success_container txt-center'>
      <div className='mg-bottom-10'>
        <img src={checked} alt='checked' width={100}></img>
      </div>
      <h1 className=' fs-28 gSemiBold txt-center mg-bottom-30'>
        Catch Report Submitted Successfully!
      </h1>
      <p className='gSemiBold mg-bottom-10 fs-21'>What happens next?</p>
      <p className='gRegular fs-17 mg-bottom-30'>
        Once the fishery verifies the catch report it will automatically be
        added to the fishery profile.
      </p>
      {catchInfo.fisheryPublicId && (
        <Button
          className={
            'sb-blue-bg  gSemiBold mg-bottom-10  brFull w-full h-fit fs-20 white txt-center  pd-10 pd-left-28 pd-right-28 '
          }
          onClick={() => {
            history.push('/fishery/' + catchInfo.fisheryPublicId);
            dispatch({
              type: TOGGLE_CATCH_REPORT,
              showCatchReportDrawer: false,
              isRequestFromProfile: false,
            });
          }}
        >
          Visit Fishery Profile
        </Button>
      )}
      <Button
        className={
          'bg-light-grey mg-bottom-10  gSemiBold brFull w-full h-fit fs-20 white txt-center  pd-10 pd-left-28 pd-right-28 '
        }
        onClick={() => {
          dispatch({
            type: TOGGLE_CATCH_REPORT,
            showCatchReportDrawer: false,
            isRequestFromProfile: false,
          });
        }}
      >
        Close
      </Button>
    </div>
  );
}

export default Success;
