import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'antd/dist/antd.min.css';
import axios from 'axios';
import moment from 'moment-timezone';
import { message } from 'antd';
import { Provider as ReduxProvider } from 'react-redux';
import { setupStore } from './store';

const reduxStore = setupStore();

moment.tz.setDefault('Europe/London');
axios.defaults.baseURL = process.env.REACT_APP_AXIOS_URL;
if (process.env?.REACT_APP_USE_NGROK) {
  axios.defaults.headers.common['ngrok-skip-browser-warning'] = true;
}

const is_mobile = window.innerWidth <= 700;
if (is_mobile) {
  message.config({
    top: '40vh',
  });
}

ReactDOM.render(
  <ReduxProvider store={reduxStore}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ReduxProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
