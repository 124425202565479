import * as React from 'react';

const SocialIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40' {...props}>
    <defs>
      <style>
        {
          '.cls-1{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:4px}'
        }
      </style>
    </defs>
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Icons'>
        <path
          stroke={'currentColor'}
          className='cls-1'
          d='M37.24 37.27 35 30a17.83 17.83 0 0 0 3-9.59 18 18 0 0 0-31.71-12M3.52 12.75A18 18 0 0 0 29.92 35c.13-.09 7.32 2.27 7.32 2.27'
        />
      </g>
    </g>
  </svg>
);

export default SocialIcon;
