import { apiSlice } from '../../store/apiSlice';
import { proApiSlice } from '../../store/proApiSlice';
import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';

export const useGetAdminFisheryIdQuery = () => {
  const fisheryIDSelector = useMemo(
    () =>
      createSelector(
        (result) => result.data,
        (adminUser) => ({
          id: adminUser?.fishery_id,
          public_id: adminUser?.fishery_public_id?.toString?.(),
        })
      ),
    []
  );
  return useGetFisheryAdminUserQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      data: fisheryIDSelector(result),
    }),
  });
};

export const useGetAdminHasAdminRoleQuery = () => {
  const fisheryIDSelector = useMemo(
    () =>
      createSelector(
        (result) => result.data,
        (adminUser) =>
          adminUser?.is_admin ??
          adminUser?.role?.toLowerCase?.()?.trim?.() === 'admin'
      ),
    []
  );
  return useGetFisheryAdminUserQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      data: fisheryIDSelector(result),
    }),
  });
};

export const extendedApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: [
      'Favourite',
      'User',
      'FisheryUser',
      'Promotion',
      'Statistics',
      'ProgressTiers',
      'Badges',
      'Queue',
      'WaitlistStatus',
    ],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getUser: builder.query({
        query: () => ({
          url: `user`,
          useDefaultErrorHandler: false,
        }),
        providesTags: (result, error) => [{ type: 'User' }],
      }),
      getFisheryAdminUser: builder.query({
        query: () => ({
          url: `fishery/admin-user`,
          useDefaultErrorHandler: false,
        }),
        providesTags: (result, error) => [{ type: 'FisheryUser' }],
      }),
      getFavourite: builder.query({
        query: () => ({
          url: `fav`,
        }),
        providesTags: (result, error) => (error ? [] : [{ type: 'Favourite' }]),
      }),
      getPromotion: builder.query({
        query: () => ({
          url: `fishery/all/promotions`,
        }),
        providesTags: (result, error) => (error ? [] : [{ type: 'Promotion' }]),
      }),
      getUserStatistics: builder.query({
        query: () => ({
          url: `get_user_statistics`,
        }),
        providesTags: (result, error) =>
          error ? [] : [{ type: 'Statistics' }],
      }),
      getTiersList: builder.query({
        query: () => ({
          url: 'ops/tiers',
        }),
        providesTags: (result, error) =>
          error ? [] : [{ type: 'ProgressTiers' }],
      }),
      getUserBadges: builder.query({
        query: () => ({
          url: 'user/achievements',
        }),
        providesTags: (result, error) => (error ? [] : [{ type: 'Badges' }]),
      }),
      getUserWallet: builder.query({
        query: () => ({
          url: `wallet`,
          useDefaultErrorHandler: false,
        }),
      }),
      getIsUserInBookingQueue: builder.query({
        query: (fisheryId) => ({
          url: `user/is_queueing?fishery_id=${fisheryId}`,
        }),
        providesTags: (result, error, fisheryId) =>
          error ? [] : [{ type: 'Queue', id: fisheryId }],
      }),
      getUserWaitlistStatus: builder.query({
        query: ({ userId = undefined, planId = undefined }) => ({
          url: `user/memberships/waitlist/status`,
          params: { plan_id: planId, user_id: userId },
        }),
        providesTags: (result, error, { planId }) =>
          error ? [] : [{ type: 'WaitlistStatus', id: planId }],
      }),
      acceptWaitlistPlace: builder.mutation({
        query: (planId) => ({
          url: `user/memberships/waitlist/accept`,
          params: { plan_id: planId },
          method: 'PUT',
        }),
        invalidatesTags: (result, error, planId) =>
          error
            ? []
            : [
                { type: 'WaitlistStatus', id: planId },
                { type: 'WaitlistStatus', id: undefined },
              ],
      }),
      rejectWaitlistPlace: builder.mutation({
        query: (planId) => ({
          url: `user/memberships/waitlist/reject`,
          params: { plan_id: planId },
          method: 'PUT',
        }),
        invalidatesTags: (result, error, planId) =>
          error
            ? []
            : [
                { type: 'WaitlistStatus', id: planId },
                { type: 'WaitlistStatus', id: undefined },
              ],
      }),
      getNewMembershipAccountDetails: builder.query({
        query: ({ planId = undefined, userId }) => ({
          url: `user/memberships/new_account_details`,
          params: { plan_id: planId, user_id: userId },
        }),
      }),
      confirmNewMembershipAccountCreation: builder.mutation({
        query: ({ planId = undefined, userId }) => ({
          url: `user/memberships/new_account_confirmation`,
          params: { plan_id: planId, user_id: userId },
          method: 'POST',
        }),
      }),
      triggerPasswordResetEmail: builder.mutation({
        query: ({ email, set = undefined }) => ({
          url: 'angler/reset',
          data: { email: email, set: set },
          method: 'POST',
        }),
      }),
    }),
  });

export const useGetUserHasSubscriptionQuery = () => {
  const hasSubscriptionSelector = createSelector(
    (result) => result.data,
    (subLevel) =>
      Boolean(
        subLevel &&
          subLevel !== 'NoSubscription' &&
          subLevel !== 'PreRegistered'
      )
  );
  return useGetUserSubscriptionLevelQuery(true, {
    selectFromResult: (result) => ({
      ...result,
      data: hasSubscriptionSelector(result),
    }),
  });
};

const EMPTY_ARRAY = [];

export const useGetAllBadgesQuery = () => {
  const allBadgesSelector = useMemo(() => {
    return createSelector(
      (result) => result.data,
      (userBadges) =>
        userBadges?.achievements?.length
          ? userBadges.achievements.flatMap(({ badges }) => badges)
          : EMPTY_ARRAY
    );
  }, []);
  return useGetUserBadgesQuery(undefined, {
    selectFromResult: (result) => ({
      ...result,
      data: allBadgesSelector(result),
    }),
  });
};

export const extendedProApiSlice = proApiSlice
  .enhanceEndpoints({ addTagTypes: ['Session', 'Subscription', 'SeenWelcome'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getNextSessionStart: builder.query({
        query: () => ({
          url: 'user/next-session-start',
          method: 'GET',
          useDefaultErrorHandler: false,
        }),
        providesTags: ['Session'],
      }),
      getUserSubscriptionLevel: builder.query({
        query: (return_pre_registered = false) => ({
          url: 'user/subscription-level',
          method: 'GET',
          useDefaultErrorHandler: false,
        }),
        transformResponse: (response, meta, return_pre_registered = false) => {
          if (response === 'PreRegistered') {
            return return_pre_registered ? response : null;
          }
          return response === 'NoSubscription' ? null : response;
        },
        providesTags: ['Subscription'],
      }),
      createNewCustomerPortal: builder.mutation({
        query: (return_url) => ({
          url: `user/customer-portal-session?return_url=${encodeURIComponent(
            return_url
          )}`,
          method: 'POST',
        }),
        transformResponse: (response) => response?.redirect_url,
      }),
      getHasSeenWelcomeScreens: builder.query({
        query: () => ({
          url: `user/has-seen-welcome`,
          method: 'GET',
        }),
        providesTags: ['SeenWelcome'],
      }),
      setHasSeenWelcomeScreens: builder.mutation({
        query: (value) => ({
          url: `user/has-seen-welcome`,
          params: { value: value },
          method: 'PUT',
        }),
        invalidatesTags: ['SeenWelcome'],
      }),
    }),
  });

export const {
  useGetFavouriteQuery,
  useGetUserStatisticsQuery,
  useGetTiersListQuery,
  useGetUserBadgesQuery,
  useGetUserQuery,
  useGetFisheryAdminUserQuery,
  useGetPromotionQuery,
  useGetUserWalletQuery,
  useGetIsUserInBookingQueueQuery,
  useGetNewMembershipAccountDetailsQuery,
  useTriggerPasswordResetEmailMutation,
  useConfirmNewMembershipAccountCreationMutation,
  useGetUserWaitlistStatusQuery,
  useAcceptWaitlistPlaceMutation,
  useRejectWaitlistPlaceMutation,
} = extendedApiSlice;

export const {
  useGetNextSessionStartQuery,
  useGetUserSubscriptionLevelQuery,
  useCreateNewCustomerPortalMutation,
  useGetHasSeenWelcomeScreensQuery,
  useSetHasSeenWelcomeScreensMutation,
} = extendedProApiSlice;
