import { Button, Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { ImageSlider } from './components/sliders';

import './assets/css/lightbox_modal.css';
import { useMemo, useState } from 'react';
import { usePreventScroll } from '../../utils/hooks';

/**
 * A lightbox modal for showing a fullscreen image (or a slider with multiple images)
 * @param isOpen {boolean} Whether to show the modal or not
 * @param initialIndex {number} (optional) The index in the image array to open the modal at
 * @param onClose {() => void} (optional) On close callback
 * @param titles {Array<String>} Titles to use for the images (if null, titles are not displayed)
 * @param images{Array<{src: String, alt: String}>} The images to show in the modal
 * @param infinite {boolean} (optional) Whether the slider repeats (is an infinite loop) -- default: true
 * @param loading {boolean} (optional) Whether to display loading spinner
 * @param hideImageCount {boolean} (optional) Whether to hide the count overlay on each image (e.g. '2/5')
 * @returns {JSX.Element}
 */
export default function ImagesLightboxModal({
  isOpen,
  initialIndex = 0,
  onClose = () => {},
  titles = null,
  images,
  infinite = true,
  loading = false,
  hideImageCount = false,
}) {
  // Index matches initialIndex not images or titles if initialIndex is non-zero
  const [currentSliderIndex, setCurrentSliderIndex] = useState(0);

  // Prevent background app scroll when Modal is open
  usePreventScroll('ImagesLightboxModal', isOpen);

  const imageSlider = useMemo(
    () => (
      <ImageSlider
        images={images}
        initialIndex={initialIndex}
        showButtons
        loading={loading}
        height={'100%'}
        openOnClick={false}
        infinite={infinite}
        onChange={(currentIndex) => setCurrentSliderIndex(currentIndex)}
        hideImageCount={hideImageCount}
      />
    ),
    [hideImageCount, images, infinite, initialIndex, loading]
  );

  return (
    <Modal
      open={isOpen}
      title={
        titles?.length && (
          <p className={'mg-0 gSemiBold fs-20'}>
            {titles?.[(initialIndex + currentSliderIndex) % images.length]}
          </p>
        )
      }
      footer={null}
      width={'100%'}
      centered
      destroyOnClose
      onCancel={() => {
        onClose();
        setCurrentSliderIndex(0);
      }}
      className={
        'image-lightbox-modal ' +
        (titles?.length ? ' image-lightbox-modal--has-title ' : '')
      }
      closeIcon={
        <Button
          shape='circle'
          icon={<CloseOutlined />}
          size={'large'}
          onClick={() => {}}
        />
      }
    >
      {imageSlider}
    </Modal>
  );
}
