import { Link } from 'react-router-dom';
import React from 'react';
import { parseIcon } from './menuOptions';

export function HorizontalMenuItem(menuItem) {
  return (
    <div className={'main-menu-mobile__item'}>
      <Link
        className='gSemiBold row-center'
        to={menuItem?.link || '#'}
        onClick={menuItem?.onClick}
      >
        {parseIcon(menuItem?.mobileIcon || menuItem?.icon)}
        {menuItem?.shortTitle || menuItem.title}
      </Link>
    </div>
  );
}
