import { apiSlice } from '../../store/apiSlice';

export const extendedApiSlice = apiSlice
  .enhanceEndpoints({
    addTagTypes: [],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSearchResults: builder.query({
        query: (body) => ({
          url: `search`,
          data: body,
          method: 'POST',
        }),
        transformResponse: (response) => response?.hits,
      }),
    }),
  });

export const { useGetSearchResultsQuery, useLazyGetSearchResultsQuery } =
  extendedApiSlice;
