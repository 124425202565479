import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Form, Input, Row } from 'antd';
import AdminLogo from './assets/images/admin_logo.png';
import './assets/css/login.css';
import axios from 'axios';
import { configure } from 'axios-hooks';

import { Helmet } from 'react-helmet';
import { ADMIN_LOGOUT_ACTION } from '../../../../AppConstants';
import { useDispatch } from 'react-redux';
import { apiSlice } from '../../../../store/apiSlice';
import { createSelector } from '@reduxjs/toolkit';
import { useGetFisheryAdminUserQuery } from '../../../user_profile/userProfileSlice';
import { notification } from '../../../../utils/messaging';

const instance = axios.create({
  withCredentials: true,
  crossDomain: true,
  baseURL: `${process.env.REACT_APP_AXIOS_URL}fishery/auth`,
  'Access-Control-Allow-Origin': '*',
  sameSite: null,
  secure: false,
});
const admin_url = process.env.REACT_APP_ADMIN_PATH;

configure({ instance });

export default function AdminLogin(props) {
  const [, setUsername] = useState(null);
  const [forgotPassword, setForgotPassword] = useState(false);

  const isAdminLoggedInSelector = useMemo(() => {
    return createSelector(
      (result) => result.data,
      (userData) => Boolean(userData)
    );
  }, []);
  const { data: isLoggedIn, isSuccess: loginStatusChecked } =
    useGetFisheryAdminUserQuery(undefined, {
      selectFromResult: (result) => ({
        ...result,
        data: isAdminLoggedInSelector(result),
      }),
    });

  useEffect(() => {
    if (loginStatusChecked && isLoggedIn) {
      props.history.push(`${admin_url}/dashboard`);
    }
  });

  const dispatch = useDispatch();

  // Reset log-out trigger state
  dispatch({
    type: ADMIN_LOGOUT_ACTION,
    trigger_logout: false,
  });

  const onFinishLogin = (values) => {
    const formData = new FormData();
    formData.append('username', values.username.trim());
    formData.append('password', values.password);

    const config = {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    };

    instance
      .post('login', formData, config)
      .then((res) => {
        setUsername('');
        props.history.push(`${admin_url}/dashboard`);
        dispatch(apiSlice.util.invalidateTags(['FisheryUser', 'User', 'PrintSettings']));
      })
      .catch(() => {
        notification.error({
          description: 'Incorrect Details. Please try again.',
        });
      })
      .finally(() => {});
  };

  const onFinishForgotPassword = (values) => {
    let payload_data = { email: values.username };
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    };

    instance
      .post('user/reset', payload_data, config)
      .then((res) => {
        notification.success({
          description: res.data.result,
        });
      })
      .catch(() => {
        notification.error({
          description: 'Incorrect Details. Please try again.',
        });
      })
      .finally(() => {});
  };

  const onFinishFailed = () => {};

  const refreshToken = () => {
    instance
      .get('refresh')
      .then((res) => {})
      .catch((err) => console.log(err))
      .finally(() => {});
  };

  const handleForgotPassword = () => {
    setForgotPassword(true);
  };

  const handleBackLogin = () => {
    setForgotPassword(false);
  };

  useEffect(() => {
    refreshToken();
  }, []);

  return (
    <div className='admin-login-page sb-dark-blue-bg'>
      <Helmet>
        <title>
          Login to Your Fishery Manager | The Best Fishery Management Software |
          swimbooker
        </title>
      </Helmet>
      <div id='admin-login-form-content'>
        <div className='login-inside-form-content'>
          <Row>
            {/* <Button onClick={handleLogout}>Logout</Button>  */}
            <Col span={24} className='txt-center mg-bottom-20'>
              <img
                className='mg-right-10'
                width='30'
                alt='admin_logo'
                src={AdminLogo}
              />
              <span className='admin-logo-text gSemiBold'>
                <span className='sb-blue fs-30'>Fishery</span>{' '}
                <span className='fs-30'>Manager</span>
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className='admin-login-form-wrapper'>
                <div className='admin-login-form-items'>
                  <Form
                    name='basic'
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={
                      forgotPassword ? onFinishForgotPassword : onFinishLogin
                    }
                    onFinishFailed={onFinishFailed}
                  >
                    <p className='dark-swim-blue gSemiBold mg-bottom-5'>
                      Email:
                    </p>
                    <Form.Item
                      // label="Username"
                      name='username'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your username!',
                        },
                      ]}
                    >
                      <Input className='br5 email' />
                    </Form.Item>

                    {forgotPassword ? (
                      <Form.Item>
                        <Button
                          className='admin-login-btn light-green-bg gBold'
                          type='primary'
                          htmlType='submit'
                        >
                          Send Login Link
                        </Button>
                      </Form.Item>
                    ) : (
                      <>
                        <p className='dark-swim-blue gSemiBold mg-bottom-5'>
                          Password:
                        </p>
                        <Form.Item
                          // label="Password"
                          name='password'
                          rules={[
                            {
                              required: true,
                              message: 'Please input your password!',
                            },
                          ]}
                        >
                          <Input.Password className='br5 admin-secret' />
                        </Form.Item>

                        <Form.Item>
                          <Button
                            className='admin-login-btn light-green-bg gBold'
                            type='primary'
                            htmlType='submit'
                          >
                            SIGN IN
                          </Button>
                        </Form.Item>
                      </>
                    )}
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
          {!forgotPassword ? (
            <Row>
              <Col span={24} className='txt-center'>
                <p>
                  <span className='dark-swim-blue gLight'>
                    Forgot your password?{' '}
                  </span>
                  <span
                    className='dark-swim-blue gSemiBold txt-underline forgot-password-link cp'
                    onClick={handleForgotPassword}
                  >
                    Click here
                  </span>
                </p>
                <div className='contact-us-info-admin-login-wrapper'>
                  <p className='contact-us-info-admin-login-content'>
                    <span className='dark-swim-blue gLight'>
                      If you are a new fishery owner and wish to claim your
                      swimbooker profile, please{' '}
                    </span>
                    <span className='dark-swim-blue gSemiBold txt-underline contact-us-link'>
                      <Link
                        className='dark-swim-blue gSemiBold txt-underline'
                        to='/contact/fishery'
                      >
                        click here
                      </Link>
                    </span>
                    <span className='dark-swim-blue gLight'>
                      {' '}
                      to contact us.
                    </span>
                  </p>
                </div>
              </Col>
            </Row>
          ) : (
            ''
          )}
        </div>
        {forgotPassword ? (
          <Button
            className='back-to-login-btn gBold'
            type='primary'
            onClick={handleBackLogin}
          >
            Back to Login
          </Button>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}
