import React, { useEffect, useState } from 'react';
import { Col, Input, Row } from 'antd';
import { useGlobalState } from './GlobalState';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';

function SearchBox({
  hasPrefix = false,
  tall = false,
  searchAdditionalClassName = '',
  onSearch = () => {},
  active,
  ...props
}) {
  const history = useHistory();
  const [contents, setContents] = useGlobalState('searchBoxContents');
  const [activeState, setActiveState] = useState(!hasPrefix);
  const showPrefix = hasPrefix && !activeState;

  const goToSearchPage = (query) => {
    onSearch();
    history.push('/search?query=' + query);
  };
  console.log(active, activeState);
  const handleOnInputChange = (event) => {
    if (event.key === 'Enter') {
      goToSearchPage(contents);
    }
    setContents(event.target.value);
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      goToSearchPage(contents);
    }
  };
  useEffect(() => {
    setActiveState(active);
  }, [active]);

  return (
    <Row id='search-full-box' {...props}>
      <Col span={24}>
        <Input
          type='text'
          name='query'
          value={contents}
          id='search-input'
          placeholder={tall && activeState ? '' : 'Search anywhere'}
          autoComplete='off'
          onChange={handleOnInputChange}
          className={
            searchAdditionalClassName +
            ' sb-search-input ' +
            (showPrefix ? 'sb-search-input__with_prefix ' : '') +
            (tall ? 'sb-search-input__tall ' : '') +
            'gLight'
          }
          onKeyDown={handleKeyDown}
          onFocus={() => setActiveState(true)}
          onBlur={() => (!contents || contents === '') && setActiveState(false)}
          prefix={
            showPrefix && <SearchOutlined className='search-icon-button left' />
          }
          suffix={
            (!hasPrefix || activeState) && (
              <SearchOutlined
                className='sb-dark-blue-bg sb-dark-blue-border search-icon-button white'
                onClick={() => goToSearchPage(contents)}
              />
            )
          }
        />
      </Col>
    </Row>
  );
}

export default SearchBox;
