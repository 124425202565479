import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Col,
  Drawer,
  notification,
  Progress,
  Row,
  Select,
} from 'antd';
import { BackButtonLayout } from '../../../../containers/BackButtonLayout';
import Step1 from './Components/Step1';
import Step2 from './Components/Step2';
import Step3 from './Components/Step3';
import Success from './Components/Success';
import axios from 'axios';
import ImageUpload from './Components/ImageUpload';
import { MENU } from '../../../../AppConstants';
import { useDispatch, useSelector } from 'react-redux';
import noImage from '../../../common/assets/images/no-photo.jpg';
import { useGlobalState } from './Components/GlobalState';
import moment from 'moment';
import dayjs from 'dayjs';

const { TOGGLE_CATCH_REPORT } = MENU;
const CatchReport = ({ isOpen }) => {
  const [current, setCurrent] = useGlobalState('current');
  const [dontHavePic, setDontHavePic] = useState(false);
  const [catchInfo, setCatchInfo] = useGlobalState('catchInfo');
  const [fisheryOption, setFisheryOption] = useGlobalState('fisheryOption');
  const [lakeOption, setLakeOption] = useGlobalState('lakeOption');
  const [filePreviews, setFilePreviews] = useGlobalState('filePreview');
  const [fileList, setFileList] = useGlobalState('fileList');
  const [validating, setValidating] = useGlobalState('validating');
  const dispatch = useDispatch();
  const [dontHaveName, setDontHaveName] = useGlobalState('dontHaveName');
  const [anglerName, setAnglerName] = useGlobalState('anglerName');
  const [date, setDate] = React.useState(dayjs(new Date()));
  const [time, setTime] = React.useState(dayjs(new Date()));

  const [isSubmittingReport, setIsSubmittingReport] = useState(false);

  const { isRequestFromProfile, isRequestFromAdmin, fisheryData } = useSelector(
    (state) => state.Menu
  );

  const [itemRefs, setItemRefs] = useState({
    species: false,
    bait: false,
    rig: false,
    fishery: false,
    lake: false,
    swim: false,
    date: false,
    time: false,
  });

  const steps = [
    {
      title: 'Basic Info & Photo',
      content: (
        <Step1
          focus={itemRefs}
          date={date}
          time={time}
          isOpen={isOpen}
          setDate={setDate}
          setTime={setTime}
        />
      ),
      progress: 33,
    },
    {
      title: 'Fishing Details',
      progress: 66,
      content: <Step2 focus={itemRefs} />,
    },
    {
      progress: 100,
      title: 'Summary',
      content: (
        <Step3
          focus={itemRefs}
          setFocus={setItemRefs}
          date={date}
          time={time}
          dontHavePic={dontHavePic}
        />
      ),
    },
  ];

  const getLake = (value) => {
    // setLoading(true);

    const axios_config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      params: { id: value },
    };

    axios
      .get('fishery', axios_config)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.result;
          setLakeOption(
            data.lakes.map((lake, i) => {
              return {
                key: i,
                label: lake.name ? (
                  <span className='gRegular'>{lake.name}</span>
                ) : (
                  ''
                ),
                value: lake?.name,
              };
            })
          );
        }
      })
      .catch((err) => {})
      .finally(() => {
        // setLoading(false);
      });
  };
  const sendReport = (userData = []) => {
    const axios_config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };
    let payload = {
      name: isRequestFromAdmin
        ? anglerName
          ? anglerName
          : 'Submitted By Fishery'
        : `${userData?.first_name} ${userData?.last_name}`,
      fishery: catchInfo.fisheryName,
      lake: catchInfo.lake ?? '',
      swim: catchInfo.swim,
      fish_name: catchInfo?.fishName,
      fish_species: catchInfo.species,
      fish_weight_lb: +catchInfo.fish_weight_lb,
      fish_weight_oz: +catchInfo.fish_weight_oz,
      rig: catchInfo.rig,
      bait: catchInfo.bait,
      hook_used: catchInfo?.hook,
      hooklink_material_used: catchInfo?.hookLink || '',
      additional_capture_information: catchInfo?.captureNote,
      additional_fish_details: catchInfo?.fishNote,
      baiting_tactics: catchInfo?.baitTactic,
      is_named_fish: catchInfo?.isNamedFish,
      length_of_session: catchInfo?.length_of_session ?? '',
      personal_best: catchInfo.personalBest,
      image_upload: !dontHavePic && fileList ? fileList : '',
      fish_caught_date: `${moment(date?.$d)
        .local()
        .format('YYYY-MM-DD')} ${moment(time?.$d).local().format('HH:mm')}`,
      is_angler_name_entered: isRequestFromAdmin && Boolean(anglerName),
    };
    axios
      .post(
        isRequestFromAdmin
          ? 'fishery/catch_report/add'
          : 'fishery/catch/report',
        payload,
        axios_config
      )
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.result;
          setIsSubmittingReport(false);
          if (isRequestFromAdmin) {
            dispatch({
              type: TOGGLE_CATCH_REPORT,
              showCatchReportDrawer: false,
              isRequestFromProfile: false,
              isRequestFromAdmin: false,
            });
          }
          notification.success({
            message: <div className='gSemiBold'>Success!</div>,
            style: { border: '1px #2CB972 solid' },
            placement: window?.innerWidth <= 500 ? 'bottom' : 'topRight',
            className: 'gRegular br10',
            description: 'Catch Report Created',
          });
          setCurrent((prev) => prev + 1);
        }
      })
      .catch((err) => {
        if (err?.response?.status !== 401 && err?.response?.status !== 200) {
          notification.error({
            message: <div className='gSemiBold'>There was an issue!</div>,
            style: { border: '1px #D34545 solid' },
            placement: window?.innerWidth <= 500 ? 'bottom' : 'topRight',
            className: 'gRegular br10',
            description: err.response.data.detail,
          });
        }
        setIsSubmittingReport(false);
      })

      .finally(() => {
        setIsSubmittingReport(false);
      });
  };

  const submitReport = () => {
    setIsSubmittingReport(true);
    if (isRequestFromAdmin) {
      sendReport();
    } else {
      const axios_config = {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      };
      let userData = [];
      axios
        .get('user', axios_config)
        .then((res) => {
          if (res.status === 200) {
            userData = res.data.result;
            sendReport(userData);
          }
        })
        .catch((err) => {
          if (err?.response?.status !== 401 && err?.response?.status !== 200) {
            notification.error({
              message: <div className='gSemiBold'>There was an issue!</div>,
              style: { border: '1px #D34545 solid' },
              placement: window?.innerWidth <= 500 ? 'bottom' : 'topRight',
              className: 'gRegular br10',
              description: err.response.data.detail,
            });
          }
          setIsSubmittingReport(false);
        });
    }
  };
  useEffect(() => {
    if (!document) {
      return;
    }
    if (isOpen) {
      setCurrent(0);
      setIsSubmittingReport(false);
      setValidating(false);
      setDontHavePic(false);
      setDontHaveName(false);
      setAnglerName('');
      setFilePreviews([]);
      setFileList('');
      setFisheryOption([]);
      setLakeOption([]);
      setCatchInfo({
        fisheryName: '',
        fisheryId: '',
        fisheryPublicId: '',
        lake: null,
        swim: '',
        date: '',
        time: '',
        species: '',
        weight: '',
        bait: '',
        hook: '',
        rig: '',
        personalBest: false,
        hookLink: '',
        isNamedFish: false,
        fishName: '',
        captureNote: '',
        fishNote: '',
        baitTactic: '',
        length_of_session: null,
        lakeDisable: true,
      });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
  }, [isOpen]);

  useEffect(() => {
    if (isRequestFromProfile || isRequestFromAdmin) {
      setCatchInfo((prevState) => ({
        ...prevState,
        fisheryName: fisheryData?.name,
        fisheryId: fisheryData?.id,
        fisheryPublicId: fisheryData?.publicId,
        lakeDisable: false,
        lake: null,
      }));
      getLake(fisheryData.publicId);
    }
  }, [isRequestFromProfile]);

  return (
    <Drawer
      title={null}
      footer={null}
      closeable={false}
      maskCloseable={false}
      className={'catch_report__drawer'}
      open={isOpen}
      onClose={() =>
        dispatch({
          type: TOGGLE_CATCH_REPORT,
          showCatchReportDrawer: false,
          isRequestFromProfile: false,
        })
      }
    >
      <BackButtonLayout
        useCrossIcon
        overlapHeader={true}
        onBack={() =>
          dispatch({
            type: TOGGLE_CATCH_REPORT,
            showCatchReportDrawer: false,
            isRequestFromProfile: false,
          })
        }
      >
        <div
          className={` ${
            current > 2 ? ' sb-blue-bg' : 'main_container_padding'
          }`}
          style={{
            display: 'flex',
            'flex-direction': 'column',
            'justify-content': 'space-between',
            minHeight: '100vh',
          }}
        >
          {current > 2 ? (
            <Success />
          ) : (
            <React.Fragment>
              <div className={'main_container_padding__content w-full'}>
                <h2
                  className='gSemiBold center '
                  style={{ paddingTop: '1rem' }}
                >
                  New Catch Report{' '}
                </h2>
                <Progress
                  percent={steps[current]?.progress}
                  showInfo={false}
                  trailColor={'#CFE4F5'}
                  strokeColor={'#2772AF'}
                />
                <h2 className='gMedium center ' style={{ paddingTop: '1rem' }}>
                  {steps[current].title}
                </h2>
                {current < 2 && (
                  <div className='catch-report-image-display'>
                    {!dontHavePic ? (
                      <ImageUpload></ImageUpload>
                    ) : (
                      <div className='center'>
                        <img
                          alt='no-image'
                          src={noImage}
                          style={{
                            height: '100%',
                            objectFit: 'cover',
                            aspectRatio: '7/3',
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}

                {current === 0 && (
                  <div className='center'>
                    <Checkbox
                      className='picture-checkbox gLight'
                      onClick={() => setDontHavePic((prev) => !prev)}
                    >
                      I haven't got a picture
                    </Checkbox>
                  </div>
                )}
                {steps[current].content}
              </div>
              <div style={{}} className='mg-top-15'>
                {current === 0 && (
                  <Button
                    className={
                      'sb-blue-bg  gSemiBold brFull w-full h-fit fs-20 white txt-center  pd-10 pd-left-28 pd-right-28'
                    }
                    onClick={() => {
                      setValidating(true);

                      if (
                        date &&
                        time &&
                        catchInfo.fish_weight_lb &&
                        catchInfo.fish_weight_oz
                      ) {
                        setCurrent((prev) => prev + 1);
                        setValidating(false);
                      } else {
                        notification.warning({
                          message: (
                            <div className='gSemiBold'>Please be aware!</div>
                          ),
                          style: { border: '1px #F7C046 solid' },
                          className: 'gRegular br10',
                          description: 'Please fill in all required fields',
                          placement:
                            window?.innerWidth <= 500 ? 'bottom' : 'topRight',
                        });
                      }
                    }}
                  >
                    Next
                  </Button>
                )}
                {current === 1 && (
                  <Row>
                    <Col
                      span={12}
                      style={{ display: 'flex', 'align-items': 'center' }}
                    >
                      <span
                        className='center cp txt-underline fs-24 gSemiBold mg-left-10'
                        onClick={() => {
                          setIsSubmittingReport(false);
                          setCurrent((prev) => prev - 1);
                        }}
                      >
                        Back
                      </span>
                    </Col>
                    <Col span={12}>
                      <Button
                        className={
                          'sb-blue-bg  gSemiBold brFull w-full h-fit fs-20 white txt-center  pd-10 pd-left-28 pd-right-28 fr'
                        }
                        style={{ maxWidth: '11rem' }}
                        onClick={() => {
                          setCurrent((prev) => prev + 1);
                        }}
                      >
                        Next
                      </Button>
                    </Col>
                  </Row>
                )}
                {current === 2 && (
                  <Row>
                    <Col span={24}>
                      <Button
                        className={
                          'light-green-bg  gSemiBold brFull w-full h-fit fs-20 white txt-center  pd-10 pd-left-28 pd-right-28 fr'
                        }
                        onClick={() => {
                          submitReport();
                        }}
                        loading={isSubmittingReport}
                      >
                        Submit
                      </Button>
                    </Col>
                    <Col
                      span={24}
                      style={{
                        display: 'flex',
                        'align-items': 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <span
                        className='center cp txt-underline fs-24 gSemiBold  mg-top-25'
                        onClick={() => {
                          setCurrent((prev) => prev - 1);
                        }}
                      >
                        Back
                      </span>
                    </Col>
                  </Row>
                )}
              </div>
            </React.Fragment>
          )}
        </div>
      </BackButtonLayout>
    </Drawer>
  );
};
export default CatchReport;
