import * as React from 'react';

const BrevoChatIcon = (props) => (
  <svg
    viewBox='0 0 94 94'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M75.7633 89.032C76.5693 89.032 77.3646 89.3386 77.9695 89.9229C81.8368 93.6573 87.478 94.9007 92.4928 93.3443C89.2654 86.6208 88.8182 78.9179 91.3192 71.7858C94.423 62.9121 95.2009 54.0179 91.6684 45.0977C88.565 37.2615 82.5762 30.751 74.9969 27.0385C75.4632 29.5244 75.6982 32.0573 75.6982 34.6169C75.6984 45.4816 71.5205 55.7001 63.9349 63.3905C56.3484 71.0817 46.1787 75.4064 35.2996 75.5681C33.2784 75.5997 31.2662 75.4809 29.2769 75.2186C35.2559 85.6989 46.5321 92.5246 58.9234 92.7085C64.344 92.7922 69.5486 91.6565 74.3957 89.3419C74.8322 89.1332 75.2996 89.032 75.7633 89.032Z'
      fill={'currentColor'}
    />
    <path
      d='M35.389 0.0069067C16.0897 -0.376306 0.0466393 15.2365 0.000104648 34.5317C-0.0112963 39.2458 0.908926 43.8165 2.73541 48.1172C2.76194 48.1798 2.7866 48.2431 2.80894 48.3073C5.30994 55.4394 4.86298 63.1421 1.63533 69.8659C6.6506 71.4215 12.2911 70.1788 16.1586 66.4442C17.1114 65.5237 18.5369 65.292 19.7324 65.8634C24.5788 68.1781 29.7841 69.3172 35.2045 69.2302C54.3528 68.9459 69.3521 53.7419 69.3519 34.6168C69.3521 15.9092 54.1162 0.383372 35.389 0.0069067ZM16.1795 39.7868C13.4067 39.7868 11.1589 37.5427 11.1589 34.7746C11.1589 32.0065 13.4067 29.7623 16.1795 29.7623C18.9523 29.7623 21.2001 32.0065 21.2001 34.7746C21.1999 37.5427 18.9523 39.7868 16.1795 39.7868ZM34.6761 39.7868C31.9033 39.7868 29.6555 37.5427 29.6555 34.7746C29.6555 32.0065 31.9033 29.7623 34.6761 29.7623C37.4489 29.7623 39.6967 32.0065 39.6967 34.7746C39.6967 37.5427 37.4489 39.7868 34.6761 39.7868ZM53.1729 39.7868C50.4002 39.7868 48.1523 37.5427 48.1523 34.7746C48.1523 32.0065 50.4002 29.7623 53.1729 29.7623C55.9457 29.7623 58.1935 32.0065 58.1935 34.7746C58.1935 37.5427 55.9457 39.7868 53.1729 39.7868Z'
      fill={'currentColor'}
    />
  </svg>
);

export default BrevoChatIcon;
