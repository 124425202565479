import HomeIcon from '../../../../assets/images/Icons/SVG/HomeIcon';
import SearchIcon from '../../../../assets/images/Icons/SVG/SearchIcon';
import SocialIcon from '../../../../assets/images/Icons/SVG/SocialIcon';
import AboutUsIcon from '../../../../assets/images/Icons/SVG/AboutUsIcon';
import React from 'react';
import ContactIconSquare from '../../../../assets/images/Icons/SVG/ContactIconSquare';
import CatchReportIcon from '../../../../assets/images/Icons/SVG/CatchReportIcon';
import UserIcon from '../../../../assets/images/Icons/SVG/UserIcon';
import BrevoChatIcon from '../../../../assets/images/Icons/SVG/BrevoChatIcon';
import ListLakeIcon from '../../../../assets/images/Icons/SVG/ListLakeIcon';

/**
 * Converts either a ReactElement or icon image data into a ReactElement with class 'in-menu-icon'
 * @param icon {ReactHTMLElement | {img: string, alt: string}}
 * @returns JSX.Element|ReactHTMLElement|undefined
 */
export const parseIcon = (icon) => {
  if (!icon) {
    return undefined;
  }
  if (icon?.img !== undefined) {
    return <img className='in-menu-icon' src={icon.img} alt={icon.alt} />;
  } else {
    return React.cloneElement(icon, {
      className: 'in-menu-icon',
    });
  }
};

export const menuOptions = [
  {
    key: 'home',
    link: '/',
    icon: <HomeIcon />,
    title: 'Home',
  },
  {
    key: 'search',
    link: '/search',
    icon: <SearchIcon />,
    title: 'Find Fisheries',
    shortTitle: 'Explore',
  },
  {
    key: 'social',
    link: '/articles',
    icon: <SocialIcon />,
    title: 'Angling Social',
    shortTitle: 'Social',
  },
  {
    key: 'about',
    link: '/about',
    icon: <AboutUsIcon />,
    title: 'About Us',
    shortTitle: 'About',
  },
  {
    key: 'contact',
    link: '/contact',
    icon: <ContactIconSquare />,
    title: 'Contact Us',
    shortTitle: 'Contact',
  },
  {
    key: 'contact',
    link: '/swimbooker-fishery-manager',
    icon: <ListLakeIcon />,
    title: 'List Your Lakes',
    shortTitle: 'List Your Lakes',
  },
  {
    key: 'catch',
    icon: <CatchReportIcon />,
    title: 'Submit Catch Report',
    shortTitle: 'Catch Report',
  },
  {
    key: 'chat',
    icon: <BrevoChatIcon />,
    title: 'Contact Support',
    shortTitle: 'Angler Support',
  },
];

export const accountMenuOptions = [
  {
    key: 'profile',
    link: '/user/profile',
    icon: null,
    mobileIcon: <UserIcon />,
    title: 'My Profile',
    shortTitle: 'Profile',
  },
];

export const getMenuItemByKey = (key) =>
  menuOptions.filter((menuOption) => menuOption.key === key)?.[0] ||
  accountMenuOptions.filter((menuOption) => menuOption.key === key)?.[0];
