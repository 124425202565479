import React from 'react';
import { Route, Switch } from 'react-router-dom';

// Admin
import AdminLoginLayout from './containers/admin/LoginLayout';
import ResetPasswordPage from './components/common/components/reset_password';
import ForgotPassword from './components/common/ForgotPassword';

const UserLoginRouter = () => (
  <>
    <AdminLoginLayout>
      <Switch>
        <Route
          exact
          path='/user/reset/:id'
          render={(props) => <ResetPasswordPage {...props} />}
        />
        <Route
          exact
          path='/user/forgot_password'
          render={(props) => <ForgotPassword {...props} />}
        />
      </Switch>
    </AdminLoginLayout>
  </>
);

export default UserLoginRouter;
