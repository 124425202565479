import {notification as antNotification} from 'antd';
import React from 'react';

export const notification = {
  open: ({
           message = undefined,
           description = undefined,
           ...props
         }) =>
    antNotification.open({
      message: <div className='gSemiBold'>{message}</div>,
      style: { border: '1px #e7e7e7 solid' },
      placement:
        props?.placement ?? window?.innerWidth <= 500 ? 'bottom' : 'topRight',
      className: 'gRegular br10',
      description: description,
      ...props,
    }),

  success: ({
              message = 'Success!',
              description = undefined,
              ...props
            }) =>
    antNotification.success({
      message: <div className='gSemiBold'>{message}</div>,
      style: { border: '1px #2CB972 solid' },
      placement:
        props?.placement ?? window?.innerWidth <= 500 ? 'bottom' : 'topRight',
      className: 'gRegular br10',
      description: description,
      ...props,
    }),

  warning: ({
              message = 'Please be aware!',
              description = undefined,
              ...props
            }) =>
    antNotification.warning({
      message: <div className='gSemiBold'>{message}</div>,
      style: { border: '1px #F7C046 solid' },
      placement:
        props?.placement ?? window?.innerWidth <= 500 ? 'bottom' : 'topRight',
      className: 'gRegular br10',
      description: description,
      ...props,
    }),

  error: ({
            message = 'There was an issue!',
            description = undefined,
            ...props
          }) =>
    antNotification.error({
      message: <div className='gSemiBold'>{message}</div>,
      style: { border: '1px #D34545 solid' },
      placement:
        props?.placement ?? window?.innerWidth <= 500 ? 'bottom' : 'topRight',
      className: 'gRegular br10',
      description: description,
      ...props,
    }),
}