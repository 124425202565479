import {
  ADMIN_LOGOUT_ACTION,
  LAKE_MANAGER,
  SET_PRINTER_ACTION,
} from '../../AppConstants';

const { UPDATE_SERVICE_TYPE } = LAKE_MANAGER;
const initialState = {
  serviceTypes: [],
  log_out: false,
  printer: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SERVICE_TYPE:
      return {
        ...state,
        serviceTypes: action.serviceTypes,
      };
    case ADMIN_LOGOUT_ACTION:
      return {
        ...state,
        log_out: action.trigger_logout,
      };
    case SET_PRINTER_ACTION:
      return {
        ...state,
        printer: action.printer,
      };
    default:
      return state;
  }
}

export default reducer;
