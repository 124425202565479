import React, { lazy, Suspense, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { Spin } from 'antd';

import Login from './components/Login';

import MainLayout from './containers/Layout';
import HomeLayout from './containers/HomeLayout';

import AdminLoginRouter from './adminLoginRoutes';
import UserLoginRouter from './userLoginRoutes';
import Home from './components/home';
import WidgetLoading from './components/widget/components/WidgetLoading';
import MemberSignUpLayout from './components/membership/components/MemberSignUpLayout';
import SandhurstAnglerDetails from './components/form/SandhurstAnglerDetails';

const FisheryPromotion = lazy(() => import('./components/fishery_promotion'));

const AdminRouter = lazy(() => import('./adminRoutes'));
const MvpAdminRouter = lazy(() => import('./mvpAdminRoutes'));

const FisheryProfile = lazy(() => import('./components/fishery_profile'));
const UserBookingsPage = lazy(() => import('./components/user_bookings'));
const UserAsyncPaymentPage = lazy(() => import('./components/async_payments'));
const SearchView = lazy(() => import('./containers/SearchView'));
const Signup = lazy(() => import('./components/signup'));
const MemberAccountCreation = lazy(
  () => import('./components/membership/components/MemberAccountCreation')
);
const MemberWaitListSignUp = lazy(
  () => import('./components/membership/components/MemberWaitListSignUp')
);
const EmailVerificationPage = lazy(
  () => import('./components/signup/components/EmailVerificationPage')
);
const UserProfile = lazy(() => import('./components/user_profile'));
const ContactUs = lazy(() => import('./components/contact_us'));
const ContactUsAnglers = lazy(
  () => import('./components/contact_us/components/anglers')
);
const ContactUsFishery = lazy(
  () => import('./components/contact_us/components/fishery')
);
const Articles = lazy(() => import('./components/articles'));
const ViewArticle = lazy(
  () => import('./components/articles/components/Article')
);

const AboutUs = lazy(() => import('./components/common/AboutUs'));
const ThirdPartyBookingWidget = lazy(() => import('./components/widget'));

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const TransparentBodyBackground = () => {
  useEffect(() => {
    if (!document?.body) {
      console.log('No document.body');
      return;
    }
    console.log(document.body);
    document.body.style.backgroundColor = 'transparent';
    return () => {
      if (document?.body) document.body.style.backgroundColor = '#fff';
    };
  }, []);

  return null;
};

const HideChatBot = () => {
  const { pathname } = useLocation();
  const chatBotWidget = document?.getElementById('brevo-conversations');

  useEffect(() => {
    if (!chatBotWidget) {
      return;
    }
    chatBotWidget.style.display = 'none';
    return () => {
      if (chatBotWidget) {
        chatBotWidget.style.display = 'block';
      }
    };
  }, [chatBotWidget, pathname]);

  return null;
};

const BaseRouter = () => {
  return (
    <Switch>
      <Route path={['/admin/login', '/sb/admin/login']} exact>
        <AdminLoginRouter />
      </Route>

      <Route path={['/user/reset/:id', '/user/forgot_password']}>
        <UserLoginRouter />
      </Route>

      <Route path={['/external/:fishery_id']}>
        <HideChatBot />
        <TransparentBodyBackground />
        <ScrollToTop />
        <Suspense fallback={<WidgetLoading />}>
          <Switch>
            <Route
              exact
              path='/external/:fishery_id'
              component={ThirdPartyBookingWidget}
            />
          </Switch>
        </Suspense>
      </Route>

      <Route path='/admin/v1/:path?/:path?/:path?/:path?' exact>
        <HideChatBot />
        <Suspense
          fallback={
            <div className='loadingContainer'>
              <Spin tip='Loading...' />
            </div>
          }
        >
          <AdminRouter />
        </Suspense>
      </Route>
      <Route path='/sb/admin/:path?/:path?/:path?/:path?' exact>
        <HideChatBot />
        <Suspense
          fallback={
            <div className='loadingContainer'>
              <Spin tip='Loading...' />
            </div>
          }
        >
          <MvpAdminRouter />
        </Suspense>
      </Route>

      <Route
        path={['/login', '/contact', '/contact/anglers', '/contact/fisheries']}
      >
        <ScrollToTop />
        <MainLayout>
          <Suspense
            fallback={
              <div className='loadingContainer'>
                <Spin tip='Loading...' />
              </div>
            }
          >
            <Switch>
              <Route
                exact
                path='/search'
                render={(props) => <SearchView {...props} />}
              />
              <Route
                exact
                path='/contact'
                render={(props) => <ContactUs {...props} />}
              />
              <Route
                exact
                path='/contact/anglers'
                render={(props) => <ContactUsAnglers {...props} />}
              />
              <Route
                exact
                path='/contact/fishery'
                render={(props) => <ContactUsFishery {...props} />}
              />
              <Route
                exact
                path='/articles'
                render={(props) => <Articles {...props} />}
              />
              <Route
                exact
                path='/article/:article_id'
                render={(props) => <ViewArticle {...props} />}
              />
            </Switch>
          </Suspense>
        </MainLayout>
      </Route>
      <Route path={['/search']}>
        <ScrollToTop />
        <Suspense
          fallback={
            <div className='loadingContainer'>
              <Spin tip='Loading...' />
            </div>
          }
        >
          <Route
            exact
            path='/search'
            render={(props) => <SearchView {...props} />}
          />
        </Suspense>
      </Route>

      <Route path={['/user/signup/step2/:id']}>
        <ScrollToTop />
        <Suspense
          fallback={
            <div className='loadingContainer'>
              <Spin tip='Loading...' />
            </div>
          }
        >
          <Switch>
            <Route
              exact
              path='/user/signup/step2/:id'
              render={(props) => <EmailVerificationPage {...props} />}
            />
          </Switch>
        </Suspense>
      </Route>
      <Route path={['/swimbooker-fishery-manager']}>
        <ScrollToTop />
        <Suspense
          fallback={
            <div className='loadingContainer'>
              <Spin tip='Loading...' />
            </div>
          }
        >
          <Switch>
            <Route
              exact
              path='/swimbooker-fishery-manager'
              render={(props) => <FisheryPromotion {...props} />}
            />
          </Switch>
        </Suspense>
      </Route>
      <Route path={['/fishery/10972/angler-details-form']}>
        <ScrollToTop />

        <Switch>
          <Route
            exact
            path='/fishery/10972/angler-details-form'
            render={(props) => <SandhurstAnglerDetails {...props} />}
          />
        </Switch>
      </Route>
      <Route path={['/about']}>
        <ScrollToTop />
        <Suspense
          fallback={
            <div className='loadingContainer'>
              <Spin tip='Loading...' />
            </div>
          }
        >
          <Switch>
            <Route exact path='/about' component={AboutUs} />
          </Switch>
        </Suspense>
      </Route>
      <Route path={['/user/signup']}>
        <ScrollToTop />
        <MainLayout isSignUp>
          <Suspense
            fallback={
              <div className='loadingContainer'>
                <Spin tip='Loading...' />
              </div>
            }
          >
            <Switch>
              <Route
                exact
                path='/user/signup'
                render={(props) => <Signup {...props} />}
              />
            </Switch>
          </Suspense>
        </MainLayout>
      </Route>
      <Route path={['/member/new_user', '/member/accept_waitlist']}>
        <Suspense
          fallback={
            <div className='loadingContainer'>
              <Spin tip='Loading...' />
            </div>
          }
        >
          <MemberSignUpLayout>
            <Switch>
              <Route
                exact={false}
                path={'/member/new_user'}
                render={(props) => <MemberAccountCreation {...props} />}
              />
              <Route
                exact={false}
                path={'/member/accept_waitlist'}
                render={(props) => <MemberWaitListSignUp {...props} />}
              />
            </Switch>
          </MemberSignUpLayout>
        </Suspense>
      </Route>
      <Route
        path={['/booking/new/:fishery_id', '/booking/progress/:fishery_id']}
      >
        <Suspense
          fallback={
            <div className='loadingContainer'>
              <Spin tip='Loading...' />
            </div>
          }
        >
          <Switch>
            <Route
              exact
              path={[
                '/booking/new/:fishery_id',
                '/booking/progress/:fishery_id',
              ]}
              render={(props) => <UserBookingsPage {...props} />}
            />
          </Switch>
        </Suspense>
      </Route>
      <Route path={'/payment'}>
        <Suspense
          fallback={
            <div className='loadingContainer'>
              <Spin tip='Loading...' />
            </div>
          }
        >
          <Switch>
            <Route
              exact={false}
              path={'/payment'}
              render={(props) => <UserAsyncPaymentPage {...props} />}
            />
          </Switch>
        </Suspense>
      </Route>
      <Route path={['/fishery/:fishery_id']}>
        <Suspense
          fallback={
            <div className='loadingContainer'>
              <Spin tip='Loading...' />
            </div>
          }
        >
          <Switch>
            <Route
              path='/fishery/:fishery_id/:drawer_key?'
              render={(props) => <FisheryProfile {...props} />}
            />
          </Switch>
        </Suspense>
      </Route>
      <Route path={['/user/profile']}>
        <ScrollToTop />
        <Suspense
          fallback={
            <div className='loadingContainer'>
              <Spin tip='Loading...' />
            </div>
          }
        >
          <Switch>
            <Route
              exact
              path='/user/profile/:section_key?'
              render={(props) => <UserProfile {...props} />}
            />
          </Switch>
        </Suspense>
      </Route>
      <Route path={['/about']}>
        <ScrollToTop />
        <Suspense
          fallback={
            <div className='loadingContainer'>
              <Spin tip='Loading...' />
            </div>
          }
        >
          <Switch>
            <Route exact path='/about' component={AboutUs} />
          </Switch>
        </Suspense>
      </Route>
      <Route path={['/articles', '/article/:article_id']}>
        <ScrollToTop />
        <HomeLayout isArticlePage>
          <Suspense
            fallback={
              <div className='loadingContainer'>
                <Spin tip='Loading...' />
              </div>
            }
          >
            <Switch>
              <Route
                exact
                path='/articles'
                render={(props) => <Articles {...props} />}
              />
              <Route
                exact
                path='/article/:article_id'
                render={(props) => <Articles {...props} />}
              />
            </Switch>
          </Suspense>
        </HomeLayout>
      </Route>
      <Route path={['/home', '/', '/login']}>
        <ScrollToTop />
        <HomeLayout>
          <Suspense
            fallback={
              <div className='loadingContainer'>
                <Spin tip='Loading...' />
              </div>
            }
          >
            <Switch>
              <Route exact path='/' component={Home} />

              <Route exact path='/home' component={Home} />

              <Route exact path='/login' component={Login} />
            </Switch>
          </Suspense>
        </HomeLayout>
      </Route>
    </Switch>
  );
};

export default BaseRouter;
