import { Button, Divider, Drawer, Menu, Spin, notification } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import React from 'react';
import { getMenuItemByKey, menuOptions } from './menuOptions';
import { VerticalMenuItem } from './VerticalMenuItem';
import UserLoginForm from '../../UserLoginForm';
import { MENU } from '../../../../AppConstants';
import { useDispatch } from 'react-redux';
import { usePreventScroll } from '../../../../utils/hooks';
const { TOGGLE_CATCH_REPORT } = MENU;

export function DefaultMenuDrawer({
  isOpen,
  setIsOpen,
  setIsCatchReportOpen,
  loading,
  isLoggedIn,
  handleLogOut,
}) {
  const dispatch = useDispatch();
  return (
    <MenuDrawer
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      showFooter={loading || isLoggedIn}
    >
      <Menu selectedKeys={[]}>
        {menuOptions.map((option) => {
          return (
            <VerticalMenuItem
              {...option}
              onClick={() => {
                if (option.key === 'catch') {
                  if (isLoggedIn) {
                    dispatch({
                      type: TOGGLE_CATCH_REPORT,
                      showCatchReportDrawer: true,
                      isRequestFromProfile: false,
                    });
                    setIsOpen(false);
                  } else {
                    notification.warning({
                      message: (
                        <div className='gSemiBold'>Please be aware!</div>
                      ),
                      style: { border: '1px #F7C046 solid' },
                      className: 'gRegular br10',
                      description: 'Please login to share catch report',
                      placement:
                        window?.innerWidth <= 500 ? 'bottom' : 'topRight',
                    });
                  }
                } else if (option.key === 'chat') {
                  window?.BrevoConversations('openChat', true);
                  setIsOpen(false);
                } else {
                  setIsOpen(false);
                }
              }}
            />
          );
        })}
      </Menu>
      <Divider />
      {loading ? (
        <div className='txt-center'>
          <Spin />
        </div>
      ) : isLoggedIn ? (
        <>
          <Menu className={'mg-bottom-10'} selectedKeys={[]}>
            <VerticalMenuItem
              {...getMenuItemByKey('profile')}
              favourMobileIcon
            />
          </Menu>
          <Button className={'mobile-logout-btn'} onClick={handleLogOut}>
            Log Out
          </Button>
        </>
      ) : (
        <UserLoginForm useMobileButtonStyle />
      )}
    </MenuDrawer>
  );
}

export default function MenuDrawer({
  isOpen,
  setIsOpen,
  showFooter,
  children,
}) {
  // Prevent background app scroll when drawer is open
  usePreventScroll('MenuDrawer', isOpen);

  return (
    <Drawer
      title={null}
      closeable={false}
      placement='right'
      onClose={() => setIsOpen(false)}
      open={isOpen}
      width={'min(100%, 30rem)'}
      headerStyle={{ display: 'none' }}
      className={'drawer-menu-mobile'}
      zIndex={1004}
    >
      <div className={'drawer-menu-mobile__header'}>
        <Button type={'text fs-28 white'} onClick={() => setIsOpen(false)}>
          <CloseCircleOutlined />
        </Button>
      </div>
      <div className={'drawer-menu-mobile__contents'}>{children}</div>
      {showFooter && (
        <div className={'drawer-menu-mobile__footer txt-center'}>
          <img
            src={process.env.PUBLIC_URL + '/assets/Logos/fish.png'}
            alt={'Swimbooker fish logo'}
          />
        </div>
      )}
    </Drawer>
  );
}
