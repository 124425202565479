import { USER } from '../../AppConstants';

const { UPDATE_USER_DETAILS } = USER;

const initialState = {
  user: '',
  isLoggedIn: '',
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case UPDATE_USER_DETAILS:
      return {
        ...state,
        user: action.user,
      };
    default:
      return state;
  }
}

export default reducer;
