import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Col, Form, Input, notification, Row, Spin } from 'antd';
import AdminLogo from './assets/images/admin_logo.png';
import './assets/css/reset_password.css';
import _ from 'lodash';
import { Helmet } from 'react-helmet';
import { useQuery, useResetPassword } from '../../../../utils/hooks';

export default function ResetPasswordPage() {
  let query = useQuery();

  const [successReset, setSuccessReset] = useState(false);

  const verificationId = useParams()['id'];
  const mode = query.get('mode');

  const [isValidLink, resetPassword, { isValidating: loading }] =
    useResetPassword(verificationId, mode);

  const onFinish = (values) => {
    if (_.isEqual(values.new_password, values.new_password_1)) {
      resetPassword(values.new_password).then(() => setSuccessReset(true));
    } else {
      notification.error({
        message: <div className='gSemiBold'>There was an issue!</div>,
        style: { border: '1px #D34545 solid' },
        placement: window?.innerWidth <= 500 ? 'bottom' : 'topRight',
        className: 'gRegular br10',
        description: "Your passwords don't match. Please try again.",
      });
    }
  };

  const onFinishFailed = () => {};

  return (
    <div className='admin-login-page sb-dark-blue-bg'>
      <Helmet>
        <meta charSet='utf-8' />
        <meta name='description' content='Reset your password on swimbooker' />
        <title>Reset Your Password | swimbooker</title>
      </Helmet>
      <div id='admin-login-form-content'>
        <div className='login-inside-form-content'>
          <Row>
            {/* <Button onClick={handleLogout}>Logout</Button>  */}
            <Col span={24} className='txt-center mg-bottom-20'>
              <img
                className='mg-right-10'
                width='30'
                alt='admin_logo'
                src={AdminLogo}
              />
              <span className='admin-logo-text gSemiBold'>
                <span className='sb-blue fs-30'>
                  {mode === 'signup'
                    ? 'Create '
                    : mode === 'reset'
                      ? 'Reset '
                      : 'Reset '}
                </span>
                <span className='fs-30'>Password</span>
              </span>
            </Col>
          </Row>
          {loading ? (
            <>
              <p className='txt-center fs-20'>Authenticating...</p>
              <div className='content-loading-icon'>
                <Spin />
              </div>
            </>
          ) : (
            ''
          )}
          {!isValidLink && !loading && !successReset ? (
            <h2 className='txt-center'>Link has expired :( </h2>
          ) : isValidLink && !loading && !successReset ? (
            <>
              <Row>
                <Col span={24}>
                  <div className='admin-login-form-wrapper'>
                    <div className='admin-login-form-items'>
                      <Form
                        name='basic'
                        initialValues={{
                          remember: true,
                        }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                      >
                        <p className='dark-swim-blue gSemiBold mg-bottom-5'>
                          Password:
                        </p>
                        <Form.Item
                          // label="Username"
                          name='new_password'
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            autocomplete='off'
                            type='password'
                            className='br5 admin-secret'
                          />
                        </Form.Item>

                        <p className='dark-swim-blue gSemiBold mg-bottom-5'>
                          Re-type Password:
                        </p>
                        <Form.Item
                          // label="Password"
                          name='new_password_1'
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                        >
                          <Input
                            autocomplete='off'
                            type='password'
                            className='br5 admin-secret'
                          />
                        </Form.Item>

                        <Form.Item>
                          <Button
                            className='admin-login-btn light-green-bg gBold'
                            type='primary'
                            htmlType='submit'
                          >
                            Reset
                          </Button>
                        </Form.Item>
                      </Form>
                    </div>
                  </div>
                </Col>
              </Row>
              {mode === 'user_reset' ? (
                <p className='txt-center'>
                  Click here to return to the <a href='/'> home page</a>
                </p>
              ) : (
                <p className='txt-center'>
                  Please, click here to <a href='/admin/login'> Login</a>
                </p>
              )}
            </>
          ) : successReset ? (
            mode === 'user_reset' ? (
              <p className='txt-center'>
                Click here to return to the <a href='/'> home page</a>
              </p>
            ) : (
              <p className='txt-center'>
                Please, click here to <a href='/admin/login'> Login</a>
              </p>
            )
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
}
