import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { notification } from 'antd';
import React from 'react';

const errorNotification = ({
  message = 'There was an issue!',
  description = undefined,
  ...props
}) =>
  notification.error({
    message: <div className='gSemiBold'>{message}</div>,
    style: { border: '1px #D34545 solid' },
    placement:
      props?.placement ?? window?.innerWidth <= 500 ? 'bottom' : 'topRight',
    className: 'gRegular br10',
    description: description,
    ...props,
  });

export const defaultErrorNotification = (err) => {
  if (axios.isAxiosError(err)) {
    if (err?.response?.status === 500) {
      errorNotification({
        description: 'Server Response failed. Please try again later',
      });
    } else if (
      err?.response?.status === 401 ||
      (err?.response?.status === 422 &&
        err?.response?.data.detail === 'Signature has expired')
    ) {
      errorNotification({
        description: 'Please Login and try again!',
      });
    } else if (err?.response?.status === 422) {
      errorNotification({
        description:
          'OOPS! There was an issue processing your request. If this persists please contact our support team',
      });
    } else {
      errorNotification({
        description: err?.response?.data.detail,
      });
    }
  } else if (err?.data?.detail) {
    errorNotification({
      description: err?.data?.detail,
    });
  } else {
    errorNotification({
      description: 'Something went wrong!',
    });
  }
};

export const axiosBaseQuery =
  ({ baseURL = '' }) =>
  async (
    { url, method, data, params, headers = {}, useDefaultErrorHandler = true },
    { dispatch }
  ) => {
    const {
      Accept = 'application/json',
      'Content-Type': ContentType = 'application/json',
      ...otherHeaders
    } = headers;
    try {
      const result = await axios({
        url: baseURL + url,
        method,
        data,
        params,
        headers: {
          Accept: Accept,
          'Content-Type': ContentType,
          ...otherHeaders,
        },
        withCredentials: true,
      });
      if (result.status === 200) {
        return { data: result.data?.result ?? result.data };
      } else {
        return {
          error: {
            status: data.status,
            data: data,
          },
        };
      }
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (useDefaultErrorHandler) {
          defaultErrorNotification(err);
        }
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
      if (useDefaultErrorHandler) {
        defaultErrorNotification(err);
      }
      return {
        error: {
          status: 500,
          data: JSON.stringify(err),
        },
      };
    }
  };

export const proApiSlice = createApi({
  baseQuery: axiosBaseQuery({
    baseURL: `${process.env.REACT_APP_PRO_URL}`,
  }),
  reducerPath: 'proApi',
  tagTypes: [],
  endpoints: () => ({}),
});
