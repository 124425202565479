import { Row, Button } from 'antd';
import SearchBox from '../../common/SearchBox';
import React, { useEffect } from 'react';
import '../assets/css/masthead.css';
import user from '../assets/images/user-icon.svg';
import TrustPilot from '../assets/images/trust-pilot.svg';
import backgroundImage1 from '../assets/images/Slide-1.jpg';
import backgroundImage2 from '../assets/images/Slide-2.jpg';
import backgroundImage3 from '../assets/images/Slide-3.jpg';
import backgroundImage4 from '../assets/images/Slide-4.jpg';
import backgroundImage5 from '../assets/images/Slide-5.jpg';
import backgroundImage6 from '../assets/images/Slide-6.jpg';
import roundedLogo from '../../../assets/images/img_blue_fish.png';

import { useHistory } from 'react-router';

function Masthead() {
  useEffect(() => {
    // Wait for the Trustpilot script to load and then initialize the widget
    const loadTrustpilotWidget = () => {
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(
          document.getElementsByClassName('trustpilot-widget')[0],
          true
        );
      }
    };

    // Check if Trustpilot script is loaded
    if (window.Trustpilot) {
      loadTrustpilotWidget();
    } else {
      // If not loaded, add an event listener to initialize once it loads
      const trustpilotScript = document.createElement('script');
      trustpilotScript.src =
        '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';
      trustpilotScript.async = true;
      trustpilotScript.onload = loadTrustpilotWidget;
      document.body.appendChild(trustpilotScript);
    }
  }, []);

  const history = useHistory();
  const callToActionButtonStyle =
    'gBold br20 fs-15 white txt-center uppercase w-full pd-12 mg-bottom-0';

  const video =
    'https://swimbooker-fishery-details.s3.eu-west-2.amazonaws.com/web_assets/videos/blue-and-grey-premium-luxurlky-geometric-triangles-2022-11-08-01-56-18-utc.mp4';
  const videoWebM =
    'https://swimbooker-fishery-details.s3.eu-west-2.amazonaws.com/web_assets/videos/blue-and-grey-premium-luxurlky-geometric-triangles-2022-11-08-01-56-18-utc.webm';

  return (
    <div className={'masthead'}>
      {/*Images shown from bottom up */}
      <img
        src={backgroundImage6}
        alt={'Aerial view of a lake'}
        className='masthead__background'
        loading={'lazy'}
      />
      <img
        src={backgroundImage5}
        alt={'Aerial view of a lake'}
        className='masthead__background'
        loading={'lazy'}
      />
      <img
        src={backgroundImage4}
        alt={'Aerial view of a lake'}
        className='masthead__background'
        loading={'lazy'}
      />
      <img
        src={backgroundImage3}
        alt={'Aerial view of a lake'}
        className='masthead__background'
        loading={'lazy'}
      />
      <img
        src={backgroundImage2}
        alt={'View of a lake and sky'}
        className='masthead__background'
        loading={'lazy'}
      />
      <img
        src={backgroundImage1}
        alt={'Aerial view of a lake'}
        className='masthead__background'
      />
      <div className='masthead-swimbooker-mobile-logo'>
        <img className='mg-right-15' width={40} src={roundedLogo} />
        <div className='gSemiBold sb-blue fs-20 white row-center'>
          swimbooker
        </div>
      </div>
      <div className={'masthead__content main_container_padding'}>
        <Row className='w-fit'>
          <h1 className='masthead__title  gBold white mg-bottom-0'>
            Less Hassle. More Fishing.
          </h1>
        </Row>
        <Row>
          <p className='fs-24 gSemiBold w-full white txt-center '>
            Book your next session with over{' '}
            <span className='gBold'>300+ lakes</span> to choose from!
          </p>
          <Row className='masthead__buttons search-box'>
            <SearchBox className={'mg-bottom-10'} tall active={false} />
          </Row>
        </Row>
        <div className='buttons-container justify-center'>
          <div className=''>
            <Button
              className='create-account-button sb-blue-bg-600 gBold fs-18'
              onClick={() => {
                history.push('user/signup');
              }}
            >
              <img src={user} alt='user' className='mg-right-10' />
              Create Free Account
            </Button>
          </div>

          <a className='browse-lake gBold cp' href='/search'>
            Browse all lakes
          </a>
        </div>

        <div
          className='trustpilot-widget gRegular mg-top-15 mg-bottom-10'
          data-locale='en-US'
          data-template-id='5419b732fbfb950b10de65e5'
          data-businessunit-id='61657394fa7737001e35bc8a'
          data-style-height='30px'
          data-style-width='100%'
          data-theme='dark'
        >
          <a
            href='https://uk.trustpilot.com/review/www.swimbooker.com'
            target='_blank'
            rel='noopener'
          >
            Trustpilot
          </a>
        </div>
      </div>
    </div>
  );
}

export default Masthead;
