import { apiSlice } from '../../store/apiSlice';

export const extendedApiSlice = apiSlice.enhanceEndpoints({}).injectEndpoints({
  endpoints: (builder) => ({
    postAnglerDetails: builder.mutation({
      query: (data) => ({
        url: `fishery/lake_exclusive_angler_details`,
        method: 'post',
        data,
      }),
    }),
  }),
});

export const { usePostAnglerDetailsMutation } = extendedApiSlice;
