import * as React from 'react';

const AboutUsIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 42 37.37' {...props}>
    <defs>
      <style>
        {
          '.cls-1{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:4px}'
        }
      </style>
    </defs>
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Icons'>
        <circle
          className='cls-1'
          stroke={'currentColor'}
          cx={27.31}
          cy={17.02}
          r={7.27}
        />
        <path
          stroke={'currentColor'}
          className='cls-1'
          d='M40 35.37h0a11.08 11.08 0 0 0-11.08-11.08h-3.46a11.08 11.08 0 0 0-11.07 11.08h0'
        />
        <circle
          stroke={'currentColor'}
          className='cls-1'
          cx={11.94}
          cy={7.59}
          r={5.59}
        />
        <path
          stroke={'currentColor'}
          className='cls-1'
          d='M13.19 13.19h-2.67A8.52 8.52 0 0 0 2 21.71h0'
        />
        <path
          stroke={'currentColor'}
          className='cls-1'
          d='M9.59 13.19h2.66a8.51 8.51 0 0 1 6.48 3'
        />
      </g>
    </g>
  </svg>
);

export default AboutUsIcon;
