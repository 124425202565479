import { apiSlice } from '../../../store/apiSlice';

export const extendedApiSlice = apiSlice
  .enhanceEndpoints({ addTagTypes: ['Catches'] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getCatches: builder.query({
        query: () => ({
          url: 'fishery/catch/report/list',
        }),
        providesTags: (result, error) => (error ? [] : [{ type: 'Catches' }]),
      }),
      getAllCatcheReports: builder.query({
        query: () => ({
          url: 'live_reports',
          params: { limit: 50 },
        }),
      }),
    }),
  });

export const { useGetCatchesQuery, useGetAllCatcheReportsQuery } =
  extendedApiSlice;
