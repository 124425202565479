import React from 'react';
import { Route, Switch } from 'react-router-dom';

import AdminLoginLayout from './containers/admin/LoginLayout';
import Login from './components/admin/components/login';
import MvpLogin from './components/MvpAdmin/Login';
import { useCheckAdminInWebview } from './utils/hooks';

function AdminLoginRouter() {
  useCheckAdminInWebview();

  return (
    <>
      <AdminLoginLayout>
        <Switch>
          <Route
            exact
            path='/admin/login'
            render={(props) => <Login {...props} />}
          />
        </Switch>
      </AdminLoginLayout>
      <Switch>
        <Route
          exact
          path='/sb/admin/login'
          render={(props) => <MvpLogin {...props} />}
        />
      </Switch>
    </>
  );
}

export default AdminLoginRouter;
