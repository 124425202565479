import { Button, notification } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { FixedMobileMenu } from './FixedMobileMenu';
import { MenuOutlined } from '@ant-design/icons';
import { DefaultMenuDrawer } from './MenuDrawer';
import SBMenuIcon from '../../../../assets/images/SwimB_125x125.png';
import hamburger from '../../../../assets/images/hamburgerMenu.svg';
import { useSelector } from 'react-redux';
import CatchReport from '../CatchReport';
import { useUserIdentification } from '../../../../utils/hooks';

function HeaderMenu({
  isLoggedInState,
  loadingState,
  triggerRedirect,
  usesFixedMobileView = false,
}) {
  const [drawerMenuOpen, setDrawerMenuOpen] = useState(false);
  const [drawerCatchReportOpen, setDrawerCatchReportOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = isLoggedInState;
  const [loading, setLoading] = loadingState;
  const firstLoad = useRef(true);
  const { showCatchReportDrawer } = useSelector((state) => state.Menu);

  const { resetIdentity } = useUserIdentification();

  const checkIfUserLoggedIn = useCallback(() => {
    const axios_config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };

    axios
      .get('auth', axios_config)
      .then((res) => {
        if (res.status === 200) {
          setIsLoggedIn(true);
          setLoading(false);
        } else {
          setIsLoggedIn(false);
          setLoading(true);
        }
      })
      .catch(() => {
        setIsLoggedIn(false);
        setLoading(false);
      });
  }, [setIsLoggedIn, setLoading]);

  useEffect(() => {
    if (firstLoad?.current) {
      checkIfUserLoggedIn();
      firstLoad.current = false;
    }
  }, [checkIfUserLoggedIn, firstLoad]);

  const handleLogOut = () => {
    const axios_config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
    };
    const pathName = window.location.pathname;

    axios
      .get('/angler/refresh-revoke', axios_config)
      .then(() => {
        axios
          .get('/angler/access-revoke', axios_config)
          .then(() => {
            if (pathName === '/user/profile') {
              notification.success({
                message: <div className='gSemiBold'>Success!</div>,
                style: { border: '1px #2CB972 solid' },
                placement: window?.innerWidth <= 500 ? 'bottom' : 'topRight',
                className: 'gRegular br10',
                description: 'Sucessfully logged out',
              });
              resetIdentity();
              setTimeout(() => {
                window.location.href = '/';
              }, 1000);
            } else {
              resetIdentity();
              window.location.href = '/';
            }
          })
          .catch(() => {});
      })
      .catch(() => {});
  };

  return (
    <>
      <div id='header-menu-right-md'>
        <Button
          className={
            'sb-box-shadow ' &&
            (usesFixedMobileView ? 'header-menu-right-md ' : '')
          }
          type='primary'
          id='header-menu-right'
          onClick={() => setDrawerMenuOpen(true)}
        >
          <img alt='Swimbooker logo' id='menu-open-icon' src={SBMenuIcon} />
          <img alt='hamburger' src={hamburger} />
        </Button>
      </div>

      <DefaultMenuDrawer
        isOpen={drawerMenuOpen}
        setIsOpen={setDrawerMenuOpen}
        isCatchReportOpen={showCatchReportDrawer}
        setIsCatchReportOpen={setDrawerCatchReportOpen}
        loading={loading}
        isLoggedIn={isLoggedIn}
        handleLogOut={handleLogOut}
      />
      <CatchReport isOpen={showCatchReportDrawer} />
      {usesFixedMobileView && (
        <FixedMobileMenu
          loading={loading}
          isLoggedIn={isLoggedIn}
          handleLogOut={handleLogOut}
        />
      )}
    </>
  );
}

export default HeaderMenu;
