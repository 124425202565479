import { createGlobalState } from 'react-hooks-global-state';
import dayjs from 'dayjs';

export const { GlobalStateProvider, useGlobalState } = createGlobalState({
  catchInfo: {
    fisheryName: '',
    fisheryId: '',
    fisheryPublicId: '',
    lake: '',
    swim: '',
    date: dayjs(new Date()),
    time: '',
    species: '',
    speciesValue: '',
    fish_weight_lb: '',
    fish_weight_oz: '',
    bait: '',
    hook: '',
    hookLink: '',
    isNamedFish: false,
    fishName: '',
    rig: '',
    personalBest: false,
    captureNote: '',
    fishNote: '',
    baitTactic: '',
    length_of_session: '',
    lakeDisable: true,
  },
  dontHaveName: false,
  anglerName: '',
  validating: false,
  current: 0,
  fileList: '',
  filePreview: [],
  fisheryOption: [],
  lakeOption: [],
  itemFocus: [
    {
      species: false,
      bait: false,
      rig: false,
      fishery: false,
      lake: false,
      swim: false,
      date: false,
      time: false,
      hook: false,
      hookLink: false,
      fishName: false,

      // Add more refs as needed
    },
  ],
});
