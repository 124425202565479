import { useRef } from 'react';
import placeholder from '../../../../common/assets/images/Image_Coming_Soon_Placeholder.jpg';

export function PlaceholderImage({
  src,
  alt,
  className = '',

  ...rest
}) {
  const ref = useRef(null);

  return (
    <img
      ref={ref}
      className={className}
      src={src || placeholder}
      alt={alt}
      loading={'lazy'}
      onError={() => {
        if (!ref?.current) {
          return;
        }
        ref.current.src = placeholder;
      }}
      {...rest}
    />
  );
}

/**
 * Mostly used for aspect ratio within slider cards
 * */
export function SliderCardImage({ src, alt, ...rest }) {
  return (
    <PlaceholderImage
      src={src}
      alt={alt}
      className={'slider-image'}
      {...rest}
    />
  );
}
