import React, { useEffect } from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import AdminLogo from '../admin/components/login/assets/images/admin_logo.png';
import '../admin/components/login/assets/css/login.css';
import axios from 'axios';
import { configure } from 'axios-hooks';
import { useTriggerPasswordResetEmailMutation } from '../user_profile/userProfileSlice';
import { notification } from '../../utils/messaging';

// axios.defaults = "http://localhost:4500/graphql", { credentials: "include" }
const instance = axios.create({
  withCredentials: true,
  crossDomain: true,
  baseURL: `${process.env.REACT_APP_AXIOS_URL}/`,
  'Access-Control-Allow-Origin': '*',
  sameSite: null,
  secure: false,
});

configure({ instance });

export default function ForgotPassword() {
  const [triggerPasswordReset] = useTriggerPasswordResetEmailMutation();

  const onFinishForgotPassword = (values) => {
    triggerPasswordReset({ email: values.username.trim() })
      .unwrap()
      .then((data) => notification.success({ description: data }))
      .catch((err) =>
        notification.error({
          description: 'Incorrect Details. Please try again.',
        })
      );
  };

  const onFinishFailed = () => {};

  const refreshToken = () => {
    instance
      .get('refresh')
      .then((res) => {})
      .finally(() => {});
  };

  useEffect(() => {
    refreshToken();
  }, []);

  return (
    <div className='admin-login-page sb-dark-blue-bg'>
      <div id='admin-login-form-content'>
        <div className='login-inside-form-content'>
          <Row>
            {/* <Button onClick={handleLogout}>Logout</Button>  */}
            <Col span={24} className='txt-center mg-bottom-20'>
              <img
                className='mg-right-10'
                width='30'
                alt='admin_logo'
                src={AdminLogo}
              />
              <span className='admin-logo-text gSemiBold'>
                <span className='sb-blue fs-30'>Verify</span>{' '}
                <span className='fs-30'>Email</span>
              </span>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className='admin-login-form-wrapper'>
                <div className='admin-login-form-items'>
                  <Form
                    name='basic'
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={onFinishForgotPassword}
                    onFinishFailed={onFinishFailed}
                  >
                    <p className='dark-swim-blue gSemiBold mg-bottom-5'>
                      Email:
                    </p>
                    <Form.Item
                      // label="Username"
                      name='username'
                      rules={[
                        {
                          required: true,
                          message: 'Please input your username!',
                        },
                      ]}
                    >
                      <Input className='br5 email' />
                    </Form.Item>
                    <Form.Item>
                      <Button
                        className='admin-login-btn light-green-bg gBold'
                        type='primary'
                        htmlType='submit'
                      >
                        Send Login Link
                      </Button>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}
