import React from 'react';
import { Row, Space, Spin } from 'antd';
import '../assets/css/home_featured_articles.css';

function FeaturedArticleCard({ id, articleData }) {
  if (!articleData) {
    return null;
  }

  return (
    <a className={'article-wrapper'} href={`/article/${articleData.id}`}>
      <img
        id={id}
        className={'home-page-article-image'}
        src={articleData.image}
        alt={'Featured image for article: ' + articleData.title}
        loading={'lazy'}
      />
      <div className='article-layout-title white mg-bottom-0 txt-center'>
        <span className='gBold txt-single-line-ellipsis fs-16'>
          {articleData.title || ''}
        </span>
        <div className='gMedium sb-blue-200 w-full fs-15'>
          Click for full article
        </div>
      </div>
    </a>
  );
}

export default function HomePageArticleContent(props) {
  const featured = props.featured;
  const articlesData = props.articles || [];
  const loading = props.loading;

  return (
    <>
      <Row className='article-angler-container'>
        <Space direction={'vertical'}>
          <span className='white gSemiBold love-angling-title'>
            The Angling Social Blog
          </span>
          <p
            className='gMedium sb-blue-100 fs-20 '
            style={{ marginBottom: '2rem' }}
          >
            Keep up to date with swimbooker & important news from the angling
            industry
          </p>
        </Space>
      </Row>

      {loading ? (
        <div className={'txt-center mg-top-100 mg-bottom-100'}>
          <Spin />
        </div>
      ) : (
        <div className='featured-articles-layout-container'>
          <FeaturedArticleCard
            articleData={featured}
            id='home-page-primary-article-image'
          />
          {articlesData.map((a, i) => (
            <FeaturedArticleCard
              key={'article_' + i}
              articleData={a}
              id={'home-page-article-image-' + i}
            />
          ))}
        </div>
      )}

      <Row>
        <a
          className='home-explore-all white txt-center br5 gBold fs-18 white'
          href='/articles'
        >
          Explore all
        </a>
      </Row>
    </>
  );
}
