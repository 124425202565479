import * as React from 'react';

const HomeIcon = (props) => (
  <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40.02 40.02' {...props}>
    <defs>
      <style>
        {
          '.cls-1{fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:4.02px}'
        }
      </style>
    </defs>
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Icons'>
        <path
          stroke={'currentColor'}
          className='cls-1'
          d='M33.15 20.92V34a4 4 0 0 1-4 4H10.87a4 4 0 0 1-4-4V20.92M2.01 16.68l18-14.67 18 14.67'
        />
      </g>
    </g>
  </svg>
);

export default HomeIcon;
