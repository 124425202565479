import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  notification,
  Row,
} from 'antd';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import anglerFormIng from './Asset/Image/sandhurstAnglerForm.jpg';
import SuccessTick from './Asset/Image/successTick.svg';
import { usePostAnglerDetailsMutation } from './formApiSlice';

const SandhurstAnglerDetails = () => {
  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  };
  const history = useHistory();
  const [isSuccessPage, setIsSuccessPage] = useState(false);
  const [postDetails] = usePostAnglerDetailsMutation();
  const [form] = Form.useForm();
  const onFormFinish = (info) => {
    postDetails({
      ...info,
      total_anglers: +info?.total_anglers,
      fishery_id: '10972',
    })
      .unwrap()
      .then(() => {
        setIsSuccessPage(true);
        notification.success({
          message: <div className='gSemiBold'>Success!</div>,
          style: { border: '1px #2CB972 solid' },
          placement: window?.innerWidth <= 500 ? 'bottom' : 'topRight',
          className: 'gRegular br10',
          description: 'Details submitted successfully',
        });
      });
  };

  return (
    <div className='sandhurst-angler-details'>
      <div
        className='box'
        style={{
          'border-radius': '8px',
          background: '#FFF',
          'box-shadow': '2px 10px 20px 5px rgba(0, 0, 0, 0.05)',
        }}
      >
        {isSuccessPage ? (
          <div
            className='mg-top-50 pd-20 pd-left-28 pd-right-28 center'
            style={{
              'border-radius': '8px',
              background: '#FFF',
              'box-shadow': '2px 10px 20px 5px rgba(0, 0, 0, 0.05)',
            }}
          >
            <img src={SuccessTick} alt='success' className='mg-bottom-10' />
            <div className='gBold fs-36 mg-bottom-10 txt-center'>
              Thank You!
            </div>
            <div
              className='gSemiBold fs-18 txt-center mg-bottom-20
            '
            >
              Your submission has been received. Gate codes and further
              information will be sent a few days prior to your session
              commencing.
            </div>
            <Button
              className='fs-16 gSemiBold white sb-blue-bg-500 pd-5 pd-left-30 pd-right-30 pd-20 center h-full br5'
              onClick={() => {
                history.push('/');
              }}
            >
              Go to homepage
            </Button>
          </div>
        ) : (
          <>
            <div
              className='center justify-center  pd-20'
              style={{ borderBottom: '1px solid #EFEFEF' }}
            >
              <img
                src={anglerFormIng}
                alt='angler Form Img'
                className='w-full  pd-20'
              />
              <div className='gBold fs-32 mg-bottom-10 txt-center'>
                Sandhurst Lake Exclusive - Angler Details
              </div>
              <div className='gSemiBold fs-24 txt-center mg-bottom-20'>
                Please complete the form below to submit all relevant
                information for your upcoming Lake Exclusive booking at
                Sandhurst Lake
              </div>
            </div>
            <Form
              {...layout}
              colon={false}
              initialValues={[]}
              className='w-full '
              onFinish={onFormFinish}
            >
              <div
                className=' mg-top-10  pd-20'
                style={{ borderBottom: '1px solid #EFEFEF' }}
              >
                <div className='gSemiBold fs-16 mg-bottom-10'>
                  Full Name (Lead Angler)
                </div>
                <Row gutter={[10, 10]} className='w-full justify-between'>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      rules={[
                        { required: true, message: 'Please input first name' },
                      ]}
                      name='first_name'
                      className='gSemiBold fs-16'
                    >
                      <Input
                        className='mg-bottom-10 gRegular'
                        placeholder='e.g. Rod'
                      />
                    </Form.Item>
                    <div className='gRegular fs-14 mg-bottom-20'>
                      First Name
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      rules={[
                        { required: true, message: 'Please input last name' },
                      ]}
                      name='last_name'
                      className='gSemiBold fs-16'
                    >
                      <Input
                        className='mg-bottom-10 gRegular'
                        placeholder='e.g. Hutchinson'
                      />
                    </Form.Item>
                    <div className='gRegular fs-14 mg-bottom-20'>Last Name</div>
                  </Col>
                </Row>
                <Row gutter={[10, 10]} className='w-full justify-between'>
                  <Col sm={24} md={12}>
                    <Form.Item
                      rules={[
                        { required: true, message: 'Please input a number' },
                      ]}
                      label='Contact Number (Lead Angler)'
                      name='contact_number'
                      className='gSemiBold fs-16'
                    >
                      <Input
                        className='mg-bottom-10 gRegular'
                        type='number'
                        placeholder='e.g. 07123 456 789'
                      />
                    </Form.Item>
                    <div className='gRegular fs-14 mg-bottom-20'>
                      Please enter a valid phone number.
                    </div>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      rules={[
                        { required: true, message: 'Please input last name' },
                      ]}
                      label='Email (Lead Angler)'
                      name='email'
                      className='gSemiBold fs-16'
                    >
                      <Input
                        className='mg-bottom-10 gRegular'
                        placeholder='e.g. example@example.com'
                      />
                    </Form.Item>
                    <div className='gRegular fs-14 mg-bottom-20'>
                      (We will send the gatecode and full fishing info to this
                      email address, please ensure it is typed correctly)
                    </div>
                  </Col>
                </Row>
                <Row className='w-full justify-between'>
                  <Col span={24}>
                    <Form.Item
                      rules={[{ required: true, message: 'Please input info' }]}
                      label='Full Address (Lead Angler)'
                      name='street_address'
                      className='gSemiBold fs-16'
                    >
                      <Input className='mg-bottom-10 gRegular' />
                    </Form.Item>
                    <div className='gRegular fs-14 mg-bottom-20'>
                      Address Line 1
                    </div>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name='street_address_line_2'
                      className='gSemiBold fs-16'
                    >
                      <Input className='mg-bottom-10 gRegular' />
                    </Form.Item>
                    <div className='gRegular fs-14 mg-bottom-20'>
                      Address Line 2
                    </div>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      rules={[{ required: true, message: 'Please input info' }]}
                      name='city'
                      className='gSemiBold fs-16'
                    >
                      <Input className='mg-bottom-10 gRegular' />
                    </Form.Item>
                    <div className='gRegular fs-14 mg-bottom-20'>City/Town</div>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      rules={[{ required: true, message: 'Please input info' }]}
                      name='postal_zip_code'
                      className='gSemiBold fs-16'
                    >
                      <Input className='mg-bottom-10 gRegular' />
                    </Form.Item>
                    <div className='gRegular fs-14 mg-bottom-20'>Post Code</div>
                  </Col>
                </Row>
                <Divider className='mg-0 mg-bottom-20' />
                <Row className='w-full justify-between pd-top-10'>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      rules={[{ required: true, message: 'Please input info' }]}
                      label='Total No. Of Anglers In Your Party (Max. 20)'
                      name='total_anglers'
                      className='gSemiBold fs-16 mg-bottom-20'
                    >
                      <Input
                        className='mg-bottom-10 gRegular'
                        placeholder='e.g. 16'
                        type='number'
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Col span={24}>
                  <Form.Item
                    rules={[{ required: true, message: 'Please input info' }]}
                    name='anglers_full_name'
                    label="List of Angler's Full Names"
                    className='gSemiBold fs-16'
                  >
                    <Input.TextArea
                      className='mg-bottom-10 gRegular'
                      style={{ height: '150px' }}
                    />
                  </Form.Item>
                  <div className='gRegular fs-14  mg-bottom-20'>
                    It is mandatory to submit the angler names via this form as
                    we cannot accept them via email or other correspondence.
                  </div>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name='list_of_guests'
                    label="List of Non-Angling Guest's Full Names"
                    className='gSemiBold fs-16 mg-bottom-20'
                  >
                    <Input.TextArea
                      className='mg-bottom-10 gRegular'
                      style={{ height: '150px' }}
                    />
                  </Form.Item>
                </Col>
                <Divider className='mg-0 mg-bottom-20' />
                <Row className='w-full justify-between pd-top-10'>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      rules={[{ required: true, message: 'This is required.' }]}
                      label='Fishery Rules & Terms Declaration'
                      name='rules'
                      className='gSemiBold fs-16'
                      valuePropName='checked'
                    >
                      <Checkbox className='mg-right-10 gRegular'>
                        I hereby declare that all anglers will be shown the
                        rules and angling information sent to me and understand
                        that a failure to comply could result in my and/or my
                        party being asked to leave the venue. I hereby accept
                        liability of my party and the fishery's terms and
                        conditions of use.
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Col xs={24} sm={12}>
                    <Form.Item
                      rules={[{ required: true, message: 'This is required.' }]}
                      label='Rod License Declaration'
                      name='license'
                      className='gSemiBold fs-16'
                      valuePropName='checked'
                    >
                      <Checkbox className='mg-right-10 gRegular'>
                        I hereby declare that all anglers in my party hold a
                        valid rod license for the duration of my booking.
                      </Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
              </div>

              <div className=' center pd-20 justify-between row-center'>
                <Button
                  className='fs-16 gSemiBold white seagreen-bg-500 pd-5 pd-left-30 pd-right-30 pd-20 center h-full br5'
                  htmlType='submit'
                >
                  Submit
                </Button>
              </div>
            </Form>
          </>
        )}
      </div>
    </div>
  );
};

export default SandhurstAnglerDetails;
