import { createApi } from '@reduxjs/toolkit/query/react';
import axios from 'axios';
import { ADMIN_LOGOUT_ACTION } from '../AppConstants';
import { defaultErrorNotification } from './proApiSlice';

const axiosBaseQuery =
  ({ baseURL } = { baseUrl: '' }) =>
  async (
    {
      url,
      method,
      data,
      params,
      headers = {},
      useDefaultErrorHandler = true,
      getFullResult = false,
    },
    { dispatch }
  ) => {
    try {
      const result = await axios({
        url: baseURL + url,
        method,
        data,
        params,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...headers,
        },
        withCredentials: true,
      });
      if (result.status === 200) {
        return {
          data: getFullResult
            ? result.data
            : result.data?.result ?? result.data,
        };
      } else {
        return {
          error: {
            status: data.status,
            data: data,
          },
        };
      }
    } catch (err) {
      if (useDefaultErrorHandler) {
        defaultErrorNotification(err);
        if (
          err?.response?.status === 401 ||
          (err?.response?.status === 422 &&
            err?.response?.data.detail === 'Signature has expired')
        ) {
          dispatch({
            type: ADMIN_LOGOUT_ACTION,
            trigger_logout: true,
          });
        }
      }
      if (axios.isAxiosError(err)) {
        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
      return {
        error: {
          status: 500,
          data: JSON.stringify(err),
        },
      };
    }
  };

export const apiSlice = createApi({
  baseQuery: axiosBaseQuery({
    baseURL: `${process.env.REACT_APP_AXIOS_URL}`,
  }),
  reducerPath: 'coreApi',
  tagTypes: [],
  endpoints: () => ({}),
});
