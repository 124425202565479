import { SliderCardImage } from './SliderImages';

/**
 * A card to be used in BaseSlider
 * @param href {string} the url to link to
 * @param image {{src: string|Image, alt: string}}
 * @param preTitle {ReactNode | null} (optional) A subtitle to be displayed above the main title
 * @param preTitleSize {number} font size in pixels for the preTitle (corresponds to class names in library.css)
 * @param preTitleClass {string} (optional) Additional class names for the preTitle
 * @param title {ReactNode} The main title
 * @param titleLimit {number} (optional) Number of characters of the title to display before an ellipsis
 * @param titleClass {string} (optional) Additional class names for the title
 * @param titleSize font size in pixels for the title (corresponds to class names in library.css)
 * @param postTitle {ReactNode | null} (optional) A subtitle to be displayed below the main title
 * @param postTitleSize font size in pixels for the postTitle (corresponds to class names in library.css)
 * @param children {ReactNode} The main content of the card
 * @param extra {ReactNode | null} (optional) An extra string to be displayed at the bottom of the card
 * @returns {JSX.Element}
 */
export default function VerticalSliderCard({
  href,
  image,
  preTitle = undefined,
  preTitleSize = 16,
  preTitleClass = ' ',
  title,
  titleLimit = 20,
  titleClass = ' ',
  titleSize = 22,
  postTitle = undefined,
  postTitleSize = 16,
  extra = undefined,
  extraClassName = '',
  imageClick = () => {},
  children,
}) {
  return (
    <div className={'slider-card ' + extraClassName}>
      <a href={href}>
        <SliderCardImage
          onClick={imageClick}
          src={image?.src}
          alt={image?.alt}
        />
        <div className='pd-10' style={{ borderRadius: '1rem' }}>
          {preTitle && (
            <p
              className={`fs-${preTitleSize} gSemiBold txt-center mg-bottom-5 sb-black ${preTitleClass}`}
            >
              {preTitle}
            </p>
          )}
          <p
            className={
              `fs-${titleSize} gBold sb-blue txt-center ${titleClass} ` +
              (!postTitle && !children && !extra
                ? ' mg-bottom-5 ' /* Avoids bottom padding if title is at bottom of card*/
                : '')
            }
          >
            {title
              ? title.length > titleLimit
                ? title.slice(0, titleLimit) + '...'
                : title
              : ''}
          </p>
          {postTitle && (
            <p
              className={`fs-${postTitleSize} gSemiBold txt-center mg-bottom-5 sb-black`}
            >
              {postTitle}
            </p>
          )}
          {children}
          {extra && (
            <p className='sb-grey txt-italic gLight txt-center mg-bottom-5'>
              {extra}
            </p>
          )}
        </div>
      </a>
    </div>
  );
}
