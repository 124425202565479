import * as React from 'react';

const ContactIconSquare = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 31.847 27.512'
    {...props}
  >
    <g
      data-name='Group 20'
      fill='none'
      stroke='currentColor'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={3}
      transform='translate(1.5 1.5)'
    >
      <path
        data-name='Path 10'
        d='M6.042 0H3.205A3.2 3.2 0 0 0 0 3.205v13.238a3.2 3.2 0 0 0 3.205 3.205h5.024a3.208 3.208 0 0 1 2.268.938l3.926 3.926 3.926-3.926a3.208 3.208 0 0 1 2.268-.938h5.024a3.2 3.2 0 0 0 3.205-3.205V3.205A3.2 3.2 0 0 0 25.642 0H9.952'
      />
      <ellipse
        data-name='Ellipse 2'
        cx={0.353}
        cy={0.361}
        rx={0.353}
        ry={0.361}
        transform='translate(20.578 9.976)'
      />
      <ellipse
        data-name='Ellipse 3'
        cx={0.353}
        cy={0.361}
        rx={0.353}
        ry={0.361}
        transform='translate(8.133 9.976)'
      />
      <ellipse
        data-name='Ellipse 4'
        cx={0.353}
        cy={0.361}
        rx={0.353}
        ry={0.361}
        transform='translate(14.36 9.976)'
      />
    </g>
  </svg>
);

export default ContactIconSquare;
