import * as React from 'react';

const CatchReportIcon = (props) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    id='Layer_1'
    data-name='Layer 1'
    viewBox='0 0 24 24'
    {...props}
  >
    <path
      fill={'currentColor'}
      d='M22.992,22.875c.043,.341-.092,.681-.358,.898-.181,.148-.406,.227-.634,.227-.256,0-2-.613-2-.613l-1.684,.562c-.327,.11-.685,.042-.951-.175-.266-.218-.401-.558-.358-.898l.095-.753c.114-.904,.555-1.657,.987-2.199-.959-1.047-2.09-2.685-2.09-4.584,0-1.427,.65-2.932,1.881-4.353,1.067-1.232,3.17-1.231,4.237,0,1.231,1.422,1.882,2.927,1.882,4.354,0,1.899-1.131,3.536-2.09,4.584,.433,.542,.874,1.295,.987,2.199l.095,.753Zm-14.992-7.875c0,1.654-1.346,3-3,3-.351,0-.687-.061-1-.172v4.172c0,1.103-.897,2-2,2s-2-.897-2-2v-6c0-.777,.446-1.452,1.096-1.783C1.592,9.479,4.449,.411,18.949-.004c.542-.003,1.052,.186,1.442,.564,.387,.377,.609,.901,.609,1.439V7c0,.553-.448,1-1,1s-1-.447-1-1V2C7.909,2.314,4.417,8.002,3.393,12.469c.465-.296,1.016-.469,1.607-.469,1.654,0,3,1.346,3,3Zm-2,0c0-.552-.449-1-1-1s-1,.448-1,1,.449,1,1,1,1-.448,1-1Z'
    />
  </svg>
);

export default CatchReportIcon;
