import { MENU } from '../../AppConstants';

const { TOGGLE_MENU, UPDATE_MENU_REF, TOGGLE_CATCH_REPORT } = MENU;

const initialState = {
  showMenu: false,
  showCatchReportDrawer: false,
  menuBarRef: '',
  insideMenuRef: '',
  isRequestFromProfile: false,
  fisheryData: {},
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_MENU:
      return {
        ...state,
        showMenu: action.showMenu,
      };
    case TOGGLE_CATCH_REPORT:
      return {
        ...state,
        showCatchReportDrawer: action.showCatchReportDrawer,
        isRequestFromProfile: action.isRequestFromProfile,
        isRequestFromAdmin: action.isRequestFromAdmin,
        fisheryData: action?.fisheryData?.name
          ? action?.fisheryData
          : state.fisheryData,
      };
    case UPDATE_MENU_REF:
      return {
        ...state,
        menuBarRef: action.menuBarRef,
        insideMenuRef: action.insideMenuRef,
      };
    default:
      return state;
  }
}

export default reducer;
