import React, { useEffect, useState } from 'react';
import './App.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './assets/css/search.css';
import './assets/css/sb.css';
import './assets/css/override.css';
import './assets/css/library.css';
import './assets/css/aws_weather.css';
import './assets/css/user_booking.css';

import {
  BrowserRouter as Router,
  matchPath,
  useLocation,
} from 'react-router-dom';
import BaseRouter from './routes';
import axios from 'axios';
import {
  CookieConsent,
  Cookies,
  getCookieConsentValue,
} from 'react-cookie-consent';
import flagsmith from 'flagsmith';
import { FlagsmithProvider } from 'flagsmith/react';
import { useLocalStorage, usePreventScroll, UserIdentificationProvider } from './utils/hooks';

import shieldImage from './assets/images/shieldGreen.png';
import { CloseOutlined } from '@ant-design/icons';
import { Button } from 'antd';

import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import timezone from 'dayjs/plugin/timezone';
import { PRIVACY_POLICY_URL } from './AppConstants';

dayjs.extend(customParseFormat);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(advancedFormat);
dayjs.extend(utc);

const instance = axios.create({
  withCredentials: true,
  crossDomain: true,
  baseURL: `${process.env.REACT_APP_AXIOS_URL}fishery/auth`,
  'Access-Control-Allow-Origin': '*',
  sameSite: null,
  secure: false,
});

const refreshToken = () => {
  instance
    .get('refresh')
    .then((res) => {})
    .catch((err) => console.log(err))
    .finally(() => {});
};

// Half a minute
// const MINUTE_MS = 30000;

// 29.5 Minutes
// const MINUTE_MS = 1770000;

// 10 Minutes
const MINUTE_MS = 600000;

setInterval(() => {
  refreshToken();
}, MINUTE_MS);

function CookieConsentBanner({ onClose = () => {} }) {
  const cookieExpiry = 182; /* ~6 months */

  /**
   * From https://github.com/Mastermindzh/react-cookie-consent/blob/master/src/CookieConsent.tsx
   */
  const setConsentCookie = (cookieValue) => {
    const cookieSecurity = window.location
      ? window.location.protocol === 'https:'
      : true;
    const cookieOptions = {
      expires: cookieExpiry,
      sameSite: 'lax',
      secure: cookieSecurity,
    };
    Cookies.set('CookieConsent', cookieValue, cookieOptions);
  };

  const grantCookiesConsent = () => {
    window.grantGTagConsent();
    window.grantFBPixelConsent();
    window.loadHeap();
  };

  const decline = () => {
    setConsentCookie(false);
  };

  const onCookieSet = () => {
    setIsOpen(false);
    onClose();
  };

  const { pathname } = useLocation();
  const isWidget = matchPath(pathname, {
    path: '/external',
    exact: false,
  });

  // Only check once on page load (not on resize) for performance reasons
  const isDesktop = Boolean(window?.innerWidth > 576);
  const [inWebview] = useLocalStorage('adminIsInWebview', false);
  const showOverlay = !isDesktop;
  const [isOpen, setIsOpen] = useState(!Boolean(getCookieConsentValue()));
  const cookieDomain = process.env?.REACT_APP_COOKIE_DOMAIN;
  const isBannerOpen = isOpen && !isWidget && !inWebview;

  // Prevent background app scroll when drawer is open
  usePreventScroll('CookieConsentBanner', isBannerOpen && showOverlay);

  /**
   * Grant cookie permissions if consent is already given on page load
   */
  useEffect(() => {
    if (getCookieConsentValue()) {
      grantCookiesConsent();
    }
  }, []);

  const isFisheryProfilePage = matchPath(pathname, {
    path: '/fishery',
    exact: false,
  });
  const isSearchPage = matchPath(pathname, {
    path: '/search',
    exact: false,
  });

  return (
    <CookieConsent
      extraCookieOptions={{
        ...(cookieDomain ? { domain: cookieDomain } : {}),
      }}
      location='bottom'
      visible={isBannerOpen ? 'byCookieValue' : 'hidden'}
      buttonText='Accept All'
      declineButtonText='Only Use Essential Cookies'
      enableDeclineButton
      flipButtons
      style={{
        background: '#F5F5F5',
        fontSize: '16px',
        fontFamily: '"gMedium", sans-serif',
        color: 'rgb(0, 0, 0, 0.85)',
      }}
      buttonClasses='cookie-accept-btn cookie-btn h-full pd-top-15 pd-bottom-15'
      buttonWrapperClasses={'cookie-btn-wrapper'}
      declineButtonClasses='cookie-decline-btn cookie-btn'
      containerClasses={
        'CookieConsent ' +
        (isFisheryProfilePage ? 'desktopCenter' : '') +
        (isSearchPage ? 'desktopRight' : '')
      }
      overlayStyle={{
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        zIndex: 11001,
      }}
      onAccept={() => {
        grantCookiesConsent();
        onCookieSet();
      }}
      onDecline={() => {
        onCookieSet();
      }}
      onOverlayClick={() => {
        decline();
        onCookieSet();
      }}
      expires={cookieExpiry}
      overlay={showOverlay}
    >
      <div className={'cookie_consent__badge'}>
        <img src={shieldImage} alt={'shield icon'} />
      </div>
      <div className={'cookie_consent__close'}>
        <Button
          icon={<CloseOutlined className={'fs-12'} />}
          shape='circle'
          type='default'
          size='small'
          onClick={() => {
            decline();
            onCookieSet();
          }}
        />
      </div>
      <div className={'cookie_consent__content'}>
        <h2 className={'cookie_consent__title black gSemiBold'}>
          We Respect Your Privacy.
        </h2>
        <span className={'cookie_consent__text black gRegular'}>
          We{' '}
          <a href={PRIVACY_POLICY_URL} className={'txt-underline'}>
            use cookies
          </a>{' '}
          to operate this website and provide the best possible user experience.
        </span>
      </div>
    </CookieConsent>
  );
}

function App() {
  const [showingCookieBanner, setShowingCookieBanner] = useState(
    !Boolean(getCookieConsentValue())
  );

  return (
    <>
      <FlagsmithProvider
        options={{
          environmentID: process.env?.REACT_APP_FLAGSMITH_KEY,
        }}
        flagsmith={flagsmith}
      >
        <UserIdentificationProvider>
          <Router>
            <CookieConsentBanner
              onClose={() => setShowingCookieBanner(false)}
            />
            <div
              className={
                'App ' + (showingCookieBanner ? 'with-cookie-banner' : '')
              }
            >
              <BaseRouter />
            </div>
          </Router>
        </UserIdentificationProvider>
      </FlagsmithProvider>
    </>
  );
}

export default App;
