import { Button } from 'antd';
import React, { useState } from 'react';
import moment from 'moment';
import noImage from '../../../../common/assets/images/no-photo.jpg';
import { useGlobalState } from './GlobalState';
import { useSelector } from 'react-redux';
function Step3({ focus, setFocus, date, time, dontHavePic }) {
  const intialFocus = {
    species: false,
    bait: false,
    rig: false,
    fishery: false,
    lake: false,
    swim: false,
    date: false,
    time: false,
    hook: false,
    hookLink: false,
    fishName: false,
  };
  const [catchInfo, setCatchInfo] = useGlobalState('catchInfo');
  const [current, setCurrent] = useGlobalState('current');
  const [fileList, setFileList] = useGlobalState('fileList');
  const { isRequestFromAdmin } = useSelector((state) => state.Menu);
  return (
    <div className='fs-16'>
      <div className='catch-report-image-display'>
        {fileList && !dontHavePic ? (
          <div className='center'>
            <img alt='catch' src={fileList} style={{ height: '100%' }} />
          </div>
        ) : (
          <div className='center'>
            <img
              alt='no-report-icon'
              src={noImage}
              style={{
                height: '100%',
                objectFit: 'cover',
                aspectRatio: '7/3',
              }}
            />
          </div>
        )}
      </div>
      <p className='gSemiBold '>
        Please confirm the details of your catch report:
      </p>

      <div className='mg-bottom-10'>
        <span className=' gSemiBold '>Fish Caught:</span>
        <div className='justify-between'>
          <span className='gRegular'>{`${catchInfo?.fish_weight_lb}lbs ${catchInfo?.fish_weight_oz}oz ${catchInfo?.length_of_session} ${catchInfo.species} `}</span>
          <div
            className='center cp txt-underline fs-16 gSemiBold '
            onClick={() => {
              setFocus({
                ...intialFocus,
                species: true,
              });
              setCurrent(0);
            }}
          >
            Edit
          </div>
        </div>
        {catchInfo?.fishName && (
          <div className='justify-between'>
            <span className='gRegular'>{catchInfo?.fishName}</span>
            <div
              className='center cp txt-underline fs-16 gSemiBold '
              onClick={() => {
                setFocus({
                  ...intialFocus,
                  fishName: true,
                });
                setCurrent(1);
              }}
            >
              Edit
            </div>
          </div>
        )}
      </div>
      <div className='mg-bottom-10'>
        <span className=' gSemiBold '>Using:</span>
        {catchInfo?.bait && (
          <div className='justify-between'>
            <span className='gRegular'>{catchInfo?.bait}</span>
            <span
              className='center cp txt-underline fs-16 gSemiBold '
              onClick={() => {
                setFocus({
                  ...intialFocus,
                  bait: true,
                });
                setCurrent(1);
              }}
            >
              Edit
            </span>
          </div>
        )}
        {catchInfo?.baitTactic && (
          <div className='justify-between'>
            <span className='gRegular'>{catchInfo?.baitTactic}</span>
            <span
              className='center cp txt-underline fs-16 gSemiBold '
              onClick={() => {
                setFocus({
                  ...intialFocus,
                  baitTactic: true,
                });
                setCurrent(1);
              }}
            >
              Edit
            </span>
          </div>
        )}
        {catchInfo?.rig && (
          <div className='justify-between'>
            <span className='gRegular'>{catchInfo?.rig}</span>
            <span
              className='center cp txt-underline fs-16 gSemiBold '
              onClick={() => {
                setFocus({
                  ...intialFocus,
                  rig: true,
                });
                setCurrent(1);
              }}
            >
              Edit
            </span>
          </div>
        )}
        {catchInfo?.hook && (
          <div className='justify-between'>
            <span className='gRegular'>{catchInfo?.hook}</span>
            <span
              className='center cp txt-underline fs-16 gSemiBold '
              onClick={() => {
                setFocus({
                  ...intialFocus,
                  hook: true,
                });
                setCurrent(1);
              }}
            >
              Edit
            </span>
          </div>
        )}
        {catchInfo?.hookLink && (
          <div className='justify-between'>
            <span className='gRegular'>{catchInfo?.hookLink}</span>
            <span
              className='center cp txt-underline fs-16 gSemiBold '
              onClick={() => {
                setFocus({
                  ...intialFocus,
                  hookLink: true,
                });
                setCurrent(1);
              }}
            >
              Edit
            </span>
          </div>
        )}
      </div>
      {catchInfo?.fisheryName && (
        <div className='mg-bottom-10'>
          <span className=' gSemiBold '>From:</span>
          <div className='justify-between'>
            <span className='gRegular'>{`${catchInfo?.fisheryName} ${
              catchInfo?.lake ? `,${catchInfo?.lake}` : ''
            }, ${catchInfo?.swim}`}</span>
            <span
              className='center cp txt-underline fs-16 gSemiBold '
              onClick={() => {
                setFocus({
                  ...intialFocus,
                  fishery: true,
                  lake: true,
                  swim: true,
                });
                setCurrent(0);
              }}
            >
              Edit
            </span>
          </div>
        </div>
      )}
      <div className='mg-bottom-10'>
        <span className=' gSemiBold '>On:</span>
        <div className='justify-between'>
          <span className='gRegular'>{`${
            date
              ? `${moment(date?.$d).local().format('dddd Do MMMM YYYY')}`
              : ''
          }
          ${date && time ? ` - ` : ''}
                  ${
                    time ? moment(time?.$d).local().format('HH:mm') : ''
                  }`}</span>
          <span
            className='center cp txt-underline fs-16 gSemiBold '
            onClick={() => {
              setFocus({
                ...intialFocus,
                date: true,
                time: true,
              });
              setCurrent(0);
            }}
          >
            Edit
          </span>
        </div>
      </div>
      <div className='mg-bottom-20'>
        <span className=' gSemiBold '>Additional Info:</span>
        {catchInfo?.captureNote && (
          <div className='justify-between'>
            <span className='gRegular'>{catchInfo?.captureNote}</span>
            <span
              className='center cp txt-underline fs-16 gSemiBold '
              onClick={() => {
                setFocus({
                  ...intialFocus,
                });
                setCurrent(1);
              }}
            >
              Edit
            </span>
          </div>
        )}
        {catchInfo?.fishNote && (
          <div className='justify-between'>
            <span className='gRegular'>{catchInfo?.fishNote}</span>
            <span
              className='center cp txt-underline fs-16 gSemiBold '
              onClick={() => {
                setFocus({
                  ...intialFocus,
                });
                setCurrent(1);
              }}
            >
              Edit
            </span>
          </div>
        )}
      </div>
      {!isRequestFromAdmin && (
        <div className='br10 bite-condition-container mg-bottom-10'>
          <div className='justify-between mg-bottom-10'>
            <div className='fs-18 gSemiBold'>Bite Conditions™</div>
            <div className='fs-16 font-montserrat row-center brFull sb-blue-bg-600 px-20 white'>
              PRO
            </div>
          </div>
          <div className='fs-16 gRegular txt-center'>
            SB+ Members will also get a complete report of weather conditions
            including; Temperature, Cloud Cover, Atmospheric Pressure, Moon
            Phase, Wind Direction and Wind Speed automatically tied to their
            catch report using the location of the fishery and the date / time
            provided.
          </div>
        </div>
      )}
    </div>
  );
}

export default Step3;
