import { Col, Row, Spin } from 'antd';
import BaseSlider from './components/BaseSlider';
import placeholder from '../../../home/assets/images/placeholder.jpeg';
import userPlaceholder from '../../../user_profile/assets/images/placeholder_user.png';
import VerticalSliderCard from './components/VerticalSliderCard';

import './assets/css/sliders.css';
import HorizontalSliderImageCard from './components/HorizontalSliderImageCard';
import { RightOutlined } from '@ant-design/icons';
import AvatarSliderCard, {
  getUserSummaryLine,
} from './components/AvatarSliderCard';

import noImage from '../../../common/assets/images/no-photo.jpg';

import { PlaceholderImage, SliderCardImage } from './components/SliderImages';
import { useState } from 'react';
import ImagesLightboxModal from '../../ImagesLightboxModal';
import { exotic_fishery } from '../../../common/Utils';
import trophy from '../sliders/assets/image/trophy.svg';
import user from '../sliders/assets/image/circle-user.svg';
import water from '../sliders/assets/image/water.svg';
import clock from '../sliders/assets/image/calendar-clock.svg';
import moment from 'moment';

import CatchReportsDrawer from './components/CatchReportsDrawer';

import { SwiperSlide } from 'swiper/react';

const HideLake1 = 'Suite Management';
const HideLake2 = 'The Fishing Lake';

function checkForEarlyReturn(loading, slidesData) {
  if (loading) {
    return (
      <div className={'txt-center mg-top-100 mg-bottom-100'}>
        <Spin />
      </div>
    );
  }

  if (!slidesData?.length) {
    return 'returnNull';
  }

  return false;
}

/**
 * Slider for a list of articles
 * @param articlesData {Array<{id: String, image?: String | Image, author: String, title: String, published_on: String}>} List of articles to be shown
 * @param loading {boolean} (optional) Whether to show the loading spinner instead of the slider
 * @returns {JSX.Element | null}
 */
export function ArticlesSlider({ articlesData, loading = false }) {
  let ordinal_suffix_of = require('../../../common/Utils').ordinal_suffix_of;
  let month = require('../../../common/Utils').month;

  const formatDate = (_date) => {
    let d = new Date(_date);
    let monthName = month[d.getMonth()];
    return (
      ' ' +
      ordinal_suffix_of(d.getDate()) +
      ' ' +
      monthName +
      ' ' +
      d.getFullYear()
    );
  };

  const returnEarly = checkForEarlyReturn(loading, articlesData);
  if (returnEarly) {
    return returnEarly === 'returnNull' ? null : returnEarly;
  }

  return (
    <BaseSlider className='limit-height'>
      {articlesData?.map((data, index) => (
        <Col className='slider-content' key={index} span={24}>
          <VerticalSliderCard
            href={`/article/${data.id}`}
            image={{
              src: data?.image || placeholder,
              alt: 'Featured image for ' + data.title,
            }}
            title={data.title}
            titleLimit={100}
            titleClass={'txt-single-line-ellipsis'}
            titleSize={16}
            postTitle={'Published on: ' + formatDate(data.published_on)}
            postTitleSize={12}
            extra={'Written by: ' + data.author}
          />
        </Col>
      ))}
    </BaseSlider>
  );
}

/**
 * Slider for a list of fisheries
 * @param fisheriesData {Array<{id: number, name: String, image?: String | Image, postcode: String, city: String, rating: number, reviews: String}>} List of fisheries to be shown
 * @param loading {boolean} (optional) Whether to show the loading spinner instead of the slider
 * @returns {JSX.Element | null}
 */
export function FisheriesSlider({ fisheriesData, loading = false }) {
  const returnEarly = checkForEarlyReturn(loading, fisheriesData);
  if (returnEarly) {
    return returnEarly === 'returnNull' ? null : returnEarly;
  }
  const breakpoints = {
    340: {
      slidesPerView: 1.2,
    },
    768: {
      slidesPerView: 2.3,
    },
    1224: {
      slidesPerView: 3.5,
    },
  };
  return (
    <BaseSlider
      breakpointsOverride={breakpoints}
      showPartialNext
      hideButtonsOnMobile
      extra=''
      noHoverAnimation
      isSwiperJs
      infinite={false}
      className='limit-height fishery-slider swiper-version'
    >
      {fisheriesData?.map((data, index) => (
        <SwiperSlide className='slider-content' key={index}>
          <div>
            <a href={`/fishery/${data.id}`}>
              <a href={`/fishery/${data.id}`} className='fishery-name'>
                {data?.name}
              </a>
              <SliderCardImage
                src={data?.image || placeholder}
                alt={'Photo of ' + data.name + ' fishery'}
              />
            </a>
          </div>
        </SwiperSlide>
      ))}
    </BaseSlider>
  );
}

/**
 * Slider for a list of lakes
 * @param lakesData {Array<{public_id: number, name: String, lake_id: String, images: {primary: String, secondary: String[]}, price: any[], max_size: number, species: {name: String,weight: number,weight_unit: String}[],type: String,max_size_unit: String,access_type: String,description: String,allow_non_angling_guest: String,arrival_hour: number,departure_hour: number,max_rod: number,booking_type: String}>} List of lakes to be shown
 * @param fisheryPublicId {string} The public ID of the fishery which the lake belongs to (used for onClick URL)
 * @param loading {boolean} (optional) Whether to show the loading spinner instead of the slider
 * @returns {JSX.Element | null}
 */
export function LakesSlider({ lakesData, fisheryPublicId, loading = false }) {
  const returnEarly = checkForEarlyReturn(loading, lakesData);
  if (returnEarly) {
    return returnEarly === 'returnNull' ? null : returnEarly;
  }

  const breakpoints = {
    desktop: {
      breakpoint: { max: 10000, min: 1200 },
      items: 3,
      slidesToSlide: 2,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 1200, min: 670 },
      items: 2,
      slidesToSlide: 2,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 670, min: 300 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 60,
    },
    smallMobile: {
      breakpoint: { max: 300, min: 0 },
      items: 1,
      slidesToSlide: 1,
      partialVisibilityGutter: 0,
    },
  };

  const idsToRemove = [HideLake1, HideLake2];

  const filteredItems =
    fisheryPublicId.toString() === exotic_fishery
      ? lakesData?.filter((item) => !idsToRemove.includes(item.name))
      : lakesData;

  return (
    <BaseSlider
      horizontal
      extra={'Click tabs for details and imagery'}
      breakpointsOverride={breakpoints}
      noHoverAnimation
      extraClassName={'black mg-top-10 gMedium'}
    >
      {filteredItems?.map((data, index) => (
        <Col className='slider-content' key={index} span={24}>
          <HorizontalSliderImageCard
            href={`/fishery/${fisheryPublicId}/lake/${data.public_id}`}
            image={{
              src: data?.images ? data.images?.primary : placeholder,
              alt: 'Photo of ' + data.name + ' fishery',
            }}
            title={data.name}
            titleSize={18}
            extraClassName={'lake'}
          >
            <p className={'gRegular mg-bottom-5'}>
              <b className={'gSemiBold'}>Type:</b>{' '}
              {data?.accommodation ? 'Accommodation' : data.type}
            </p>
            <p className={'gRegular mg-bottom-5'}>
              <b className={'gSemiBold'}>Access:</b> {data.access_type}
            </p>
            {!data?.accommodation && (
              <p className={'gRegular mg-bottom-5'}>
                <b className={'gSemiBold'}>Max Size:</b>{' '}
                {data.max_size + ' ' + data.max_size_unit}
              </p>
            )}
          </HorizontalSliderImageCard>
        </Col>
      ))}
    </BaseSlider>
  );
}

export function CatchReportSlider({
  isHomePage = false,
  lakesData,
  fisheryPublicId,
  loading = false,
  showFishery = false,
}) {
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [moreDetailOpen, setMoreDetailOpen] = useState(false);
  const [catchImage, setCatchImage] = useState(false);
  const [catchDetails, setCatchDetails] = useState([]);
  const returnEarly = checkForEarlyReturn(loading, lakesData);
  if (returnEarly) {
    return returnEarly === 'returnNull' ? null : returnEarly;
  }

  const breakpoints = {
    340: {
      slidesPerView: 1.2,
    },
    768: {
      slidesPerView: 2.2,
    },
    1224: {
      slidesPerView: 3.3,
    },
  };

  const idsToRemove = [HideLake1, HideLake2];

  const filteredItems =
    fisheryPublicId?.toString() === exotic_fishery
      ? lakesData?.filter((item) => !idsToRemove.includes(item.name))
      : lakesData;

  return (
    <>
      <ImagesLightboxModal
        isOpen={lightboxOpen}
        onClose={() => {
          setLightboxOpen(false);
          setCatchImage([]);
        }}
        infinite={false}
        images={catchImage}
        loading={!catchImage}
      />
      <CatchReportsDrawer
        drawerOpen={moreDetailOpen}
        setIsDrawerOpen={setMoreDetailOpen}
        catchDetails={catchDetails}
      />

      <BaseSlider
        horizontal
        extra={''}
        isSwiperJs
        swiperSpaceBetween={20}
        breakpointsOverride={breakpoints}
        noHoverAnimation
        hideButtonsOnMobile={true}
        showPartialNext={isHomePage}
        className={`catch-slider homepage-catch-slider swiper-version`}
        extraClassName={'black mg-top-10 gMedium'}
      >
        {filteredItems?.map((data, index) => (
          <SwiperSlide className='cp'>
            <div
              className='slider-content'
              key={filteredItems?.id}
              span={24}
              onClick={() => {
                setCatchImage(
                  data?.image_upload
                    ? [{ src: data.image_upload, alt: 'catch-image' }]
                    : [{ src: noImage, alt: 'catch-image' }]
                );
                setCatchDetails(data);
                setMoreDetailOpen(true);
              }}
            >
              {isHomePage ? (
                <>
                  <SliderCardImage
                    src={data?.image_upload || noImage}
                    alt='catch-image'
                  />
                  {data?.user_subscription_level == 'Pro' && (
                    <div className='slider-pro-icon sb-blue-bg-800 font-montserrat'>
                      PRO
                    </div>
                  )}
                </>
              ) : (
                <Col className='h-full'>
                  <VerticalSliderCard
                    image={{
                      src: data?.image_upload ? data.image_upload : noImage,
                      alt: 'Photo of ' + data.name + ' fishery',
                    }}
                    extraClassName='br15'
                    titleSize={18}
                    titleClass='mg-bottom-0'
                  >
                    {data.personal_best && (
                      <div
                        className='brFull bg-white sb-blue fs-17 gBold row-center'
                        style={{
                          position: 'absolute',
                          top: '10px',
                          right: '4%',
                          padding: '0 15px',
                        }}
                      >
                        <img
                          height={21}
                          src={trophy}
                          className='mg-right-5'
                          alt='trophy'
                        ></img>
                        PB
                      </div>
                    )}
                    <div className='center'>
                      <Row
                        justify='center'
                        style={{
                          minWidth: '70%',
                          position: 'absolute',
                          marginTop: '-40px',
                          background: 'white',
                          padding: '0 12px',
                        }}
                        className='br5 gMedium'
                      >
                        <div className='row-center mg-right-15'>
                          <img
                            width={16}
                            src={user}
                            className='mg-right-10'
                            alt={data?.name}
                          ></img>
                          <div>{data?.name}</div>
                        </div>
                        <div>
                          {`${data?.fish_species} | ${data?.fish_weight_lb}lb ${data?.fish_weight_oz}oz`}{' '}
                        </div>
                      </Row>
                      <Row
                        className='gSemiBold w-full fs-15 mg-bottom-5'
                        justify='space-around'
                        style={
                          showFishery && {
                            flexDirection: 'column',
                            alignItems: 'center',
                          }
                        }
                      >
                        <Col
                          // span={11}
                          className='mg-right-10'
                        >
                          <div className='row-center '>
                            {!showFishery && (
                              <img
                                width={16}
                                src={water}
                                className='mg-right-10'
                                alt={showFishery ? data?.fishery : data?.lake}
                              ></img>
                            )}
                            <div
                              style={
                                showFishery
                                  ? {
                                      color: '#1F5B8C',
                                      fontWeight: 600,
                                      fontSize: '20px',
                                      textAlign: 'center',
                                      lineHeight: '25px',
                                    }
                                  : {}
                              }
                            >
                              {showFishery ? data?.fishery : data?.lake}
                            </div>
                          </div>
                        </Col>

                        <Col
                          // span={11}
                          className='mg-right-10'
                        >
                          <div className='row-center fr'>
                            <img
                              width={16}
                              src={clock}
                              className='mg-right-10'
                              alt='calendar'
                            ></img>
                            <div>
                              {moment(data?.fish_caught_date).format(
                                'DD/MM/YYYY HH:mm'
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row justify={'center'}>
                        <span
                          className='gRegular sb-blue txt-underline fs-16'
                          onClick={() => {
                            setCatchDetails(data);
                            setMoreDetailOpen(true);
                          }}
                        >
                          More Details
                        </span>
                      </Row>
                    </div>
                  </VerticalSliderCard>
                </Col>
              )}
            </div>
            {isHomePage && (
              <div className='mg-left-10 mg-right-10 mg-top-20'>
                <div className='fs-16 gMedium grey-700'>{data?.name}</div>
                <div className='fs-20 gBold grey-900'>{data?.fishery}</div>
              </div>
            )}
          </SwiperSlide>
        ))}
      </BaseSlider>
    </>
  );
}

/**
 * Slider for a list of reviews
 * @param reviewsData {Array<{comment: String, commented_at: String, first_name: String, last_name: String, personal_best:{species: String, unit: String, weight: String}, profile_image: String, years_angling: String}>} List of reviews to be shown
 * @param fisheryPublicId {string} The public ID of the fishery which the reviews relate to (used for onClick URL)
 * @param loading {boolean} (optional) Whether to show the loading spinner instead of the slider
 * @returns {JSX.Element | null}
 */
export function ReviewsSlider({
  reviewsData,
  fisheryPublicId,
  loading = false,
}) {
  const returnEarly = checkForEarlyReturn(loading, reviewsData);
  if (returnEarly) {
    return returnEarly === 'returnNull' ? null : returnEarly;
  }

  return (
    <BaseSlider
      horizontal
      extra={null}
      showPartialNext
      noHoverAnimation
      hideButtonsOnMobile
      infinite={false}
    >
      {reviewsData?.map((data, index) => (
        <Col className='slider-content' key={index} span={24}>
          <AvatarSliderCard
            href={`/fishery/${fisheryPublicId}/reviews/${index + 1}`}
            image={{
              src: data?.profile_image || userPlaceholder,
              alt: data.first_name + ' ' + data.last_name,
            }}
            title={data.first_name + ' ' + data.last_name}
            postTitle={getUserSummaryLine(data)}
            extra={
              <>
                Show more <RightOutlined />
              </>
            }
          >
            <p className={'gRegular mg-bottom-5 review-slider-preview'}>
              {data.comment}
            </p>
          </AvatarSliderCard>
        </Col>
      ))}
    </BaseSlider>
  );
}

/**
 * A full-width slider for images
 * @param images {Array<{src: String, alt: String}>} List of images to be shown
 * @param loading {boolean} (optional) Whether to show the loading spinner instead of the slider
 * @param openOnClick {boolean} (optional) Whether to open the image in a zoomable lightbox modal when clicked
 * @param height {String} (optional) The height of the slider (used for preventing content movement during image loading)
 * @param showButtons {boolean} (optional) Whether to show the left-right buttons
 * @param hideImageCount {boolean} (optional) Whether to hide the count overlay on each image (e.g. '2/5')
 * @param infinite {boolean} (optional) Whether the slider repeats (is an infinite loop) -- default: true. Note: this only works if initialIndex == 0
 * @param initialIndex {number} (optional) The index of the slide to display first (0 by default)
 * @param onChange {(currentIndex: number) => void} (optional) A function to carry out when the slider changes (index may be inaccurate when infinite=true)
 * @returns {JSX.Element | null}
 */
export function ImageSlider({
  images,
  loading = false,
  height = '20rem',
  showButtons = false,
  hideImageCount = false,
  initialIndex = 0,
  openOnClick = false,
  infinite = false,
  onChange = (currentIndex) => {},
}) {
  const [lightboxIndex, setLightboxIndex] = useState(null);

  const returnEarly = checkForEarlyReturn(loading, images);
  if (returnEarly) {
    return (
      <div style={{ height: height }}>
        {returnEarly === 'returnNull' ? null : returnEarly}
      </div>
    );
  }

  return (
    <div
      style={{ height: height }}
      className={
        'image_slider ' + (openOnClick ? ' image_slider__clickable ' : '')
      }
    >
      <BaseSlider
        fullWidth
        hideButtonsOnMobile
        extra={null}
        hideButtons={!showButtons || images.length <= 1}
        onChange={onChange}
        infinite={
          /* Must be true if initialIndex is non-zero otherwise the image numbering won't make sense  */
          initialIndex ? true : infinite
        }
      >
        {[...images.slice(initialIndex), ...images.slice(0, initialIndex)]?.map(
          (data, index) => (
            <Col
              className='slider-content'
              key={index}
              span={24}
              style={{ height: height }}
              onClick={() => openOnClick && setLightboxIndex(index)}
            >
              <PlaceholderImage
                src={data?.src}
                alt={data.alt}
                className={'image_slider_image'}
              />
              {!hideImageCount && (
                <div className={'slider-count'}>
                  {((initialIndex + index) % images.length) + 1} /{' '}
                  {images.length}
                </div>
              )}
            </Col>
          )
        )}
      </BaseSlider>
      {openOnClick && (
        <ImagesLightboxModal
          isOpen={lightboxIndex !== null}
          initialIndex={lightboxIndex}
          onClose={() => setLightboxIndex(null)}
          images={images}
          loading={loading}
        />
      )}
    </div>
  );
}
