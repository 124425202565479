import { Avatar } from 'antd';
import userPlaceholder from '../../../../user_profile/assets/images/placeholder_user.png';
import { HashLink } from 'react-router-hash-link';

export const getUserSummaryLine = (data) => (
  <span>
    {data?.years_angling && (
      <>
        <span className={'gSemiBold'}>Years Angling:</span>{' '}
        <span>{data.years_angling} </span>
      </>
    )}
    {data?.personal_best && (
      <>
        <span className={'gSemiBold'}>PB:</span>{' '}
        <span>
          {data.personal_best.species} {data.personal_best.weight}&nbsp;
          {data.personal_best.unit}
        </span>
      </>
    )}
  </span>
);

/**
 * A header with an avatar (profile image), title and subtitle
 * See AvatarSliderCard for prop definitions
 */
export function AvatarCardHeader({
  image,
  avatarSize = 50,
  title,
  titleSize = 18,
  postTitle = undefined,
  postTitleSize = 10,
}) {
  return (
    <div className='avatar-card-header mg-bottom-20'>
      <Avatar
        size={avatarSize}
        src={
          <img
            src={image?.src || userPlaceholder}
            alt={image?.alt}
            loading={'lazy'}
          />
        }
        className='slider-avatar'
      />
      <div className='avatar-card-header__content'>
        <p className={`fs-${titleSize} gSemiBold mg-bottom-2`}>
          {title
            ? title.length > 20
              ? title.slice(0, 20) + '...'
              : title
            : ''}
        </p>
        {postTitle && (
          <p className={`fs-${postTitleSize} gRegular mg-bottom-5 sb-black`}>
            {postTitle}
          </p>
        )}
      </div>
    </div>
  );
}

/**
 * A card to be used in BaseSlider with an avatar-style (round) image (not full-width image)
 * @param href {History.LocationDescriptor<H.LocationState> | ((location: History.Location<History.LocationState>) => History.LocationDescriptor<History.LocationState>)} the url to link to
 * @param image {{src: string|Image, alt: string}}
 * @param avatarSize {number} (option) size of the avatar image in px
 * @param title {ReactNode} The main title
 * @param titleSize font size in pixels for the title (corresponds to class names in library.css)
 * @param postTitle {ReactNode | null} (optional) A subtitle to be displayed below the main title
 * @param postTitleSize font size in pixels for the postTitle (corresponds to class names in library.css)
 * @param children {ReactNode} The main content of the card
 * @param extra {ReactNode | null} (optional) An extra string to be displayed at the bottom of the card
 * @returns {JSX.Element}
 */
export default function AvatarSliderCard({
  href,
  image,
  avatarSize = 50,
  title,
  titleSize = 18,
  postTitle = undefined,
  postTitleSize = 10,
  extra = undefined,
  children,
}) {
  return (
    <div className='slider-card avatar pd-20'>
      <HashLink to={href}>
        <div>
          <AvatarCardHeader
            {...{
              image,
              avatarSize,
              title,
              titleSize,
              postTitle,
              postTitleSize,
            }}
          />
          {children}
        </div>
        {extra && (
          <p className=' gSemiBold black w-full txt-left mg-top-15'>{extra}</p>
        )}
      </HashLink>
    </div>
  );
}
