import { Col, Row } from 'antd';
import React from 'react';
import { useHistory } from 'react-router-dom';
const imagePaths = {
  exotic: require('../assets/images/Exotic-image.png'),
  specimen: require('../assets/images/specimen-image.png'),
  thailand: require('../assets/images/Europe-image.png'),
  europe: require('../assets/images/Thailand-image.png'),
  pleasure: require('../assets/images/Pleasure-lake-image.png'),
  appLake: require('../assets/images/app-lake-placeholder.png'),
  checked: require('../assets/images/checked-bullet.svg').default,
  appQR: require('../assets/images/AppDownload.svg').default,
  appStoreIcon:
    require('../../common/assets/images/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg')
      .default,
  googlePlayIcon: require('../../common/assets/images/google-play-badge.png'),
};

// Prefetch the images
Object.values(imagePaths).forEach((src) => {
  const img = new Image();
  img.src = src;
});

function HomePageVenueContent() {
  const history = useHistory();
  return (
    <div>
      <div className='fs-38 gSemiBold sb-blue-800'>
        Find Your <span className='sb-blue-900 gBold'> Perfect Venue!</span>
      </div>
      <div className='grey-700 gMedium fs-20 mg-bottom-15'>
        swimbooker is the perfect place to book your next session or holiday.
      </div>
      <Row gutter={[40, 40]} className='mg-bottom-20'>
        <Col span={24} md={12}>
          <div
            className='venue-type-container cp'
            onClick={() =>
              history.push(`/search`, {
                homeFilter: '10a7a430-d2e0-4b2c-8284-1dcb4b5eeeb7',
              })
            }
          >
            <div className='text-container'>Explore Lodge Stays</div>
            <img src={imagePaths.exotic} alt='venue-placeholder' />
          </div>
        </Col>
        <Col span={24} md={12}>
          <div
            className='venue-type-container cp'
            onClick={() =>
              history.push(`/search`, {
                homeFilter: 'd778fd21-187e-406a-9615-c54362d67054',
              })
            }
          >
            <div className='text-container'>Explore Specimen Waters</div>
            <img src={imagePaths.specimen} alt='venue-placeholder' />
          </div>
        </Col>
        <Col span={24} md={8}>
          <div
            className='venue-type-container cp'
            onClick={() =>
              history.push(`/search`, {
                homeFilter: '0157a475-135b-462e-96da-da4342c1b10b',
              })
            }
          >
            <img src={imagePaths.europe} alt='venue-placeholder' />
            <div className='grey-700 fs-16 gMedium mg-top-10'>On Tour</div>
            <div className='grey-900 fs-16 gBold'>
              Fishing Holidays in Europe
            </div>
          </div>
        </Col>

        <Col span={24} md={8}>
          <div
            className='venue-type-container cp'
            onClick={() =>
              history.push(`/search`, {
                homeFilter: '7ab79dbc-94a6-4b65-a559-dd5a5b0419cd',
              })
            }
          >
            <img src={imagePaths.thailand} alt='venue-placeholder' />
            <div className='grey-700 fs-16 gMedium mg-top-10'>
              Further Afield
            </div>
            <div className='grey-900 fs-16 gBold'>
              Fishing Holidays in Thailand
            </div>
          </div>
        </Col>
        <Col span={24} md={8}>
          <div
            className='venue-type-container cp'
            onClick={() =>
              history.push(`/search`, {
                homeFilter: '158a113c-751d-430a-bff7-fb15d041a04b',
              })
            }
          >
            <img src={imagePaths.pleasure} alt='venue-placeholder' />
            <div className='grey-700 fs-16 gMedium mg-top-10'>
              Family Day Out
            </div>
            <div className='grey-900 fs-16 gBold'>
              Explore Family Friendly Lakes
            </div>
          </div>
        </Col>
        <Col span={24}>
          <Row className='mobile-app-container'>
            <div className='img-container'>
              <img src={imagePaths.appLake} alt='lake-image' />
            </div>
            <div
              className='  justify-between'
              style={{
                flex: 1,
                flexDirection: 'column',
                padding: '20px 20px 15px ',
              }}
            >
              <div className='row-center flex-wrap'>
                <div className='benefits'>
                  <div className='fs-32 grey-900 gBold mg-bottom-20 text-center'>
                    Go further with the swimbooker app
                  </div>
                  <div>
                    <div className='fs-18 gSemiBold grey-800 mg-bottom-15'>
                      <img
                        src={imagePaths.checked}
                        alt='checked'
                        className='mg-right-15'
                      />
                      Direct Notification Updates
                    </div>
                    <div className='fs-18 gSemiBold grey-800 mg-bottom-15'>
                      <img
                        src={imagePaths.checked}
                        alt='checked'
                        className='mg-right-15'
                      />
                      MySB - Your Angler Hub
                    </div>
                    <div className='fs-18 gSemiBold grey-800 mg-bottom-15'>
                      <img
                        src={imagePaths.checked}
                        alt='checked'
                        className='mg-right-15'
                      />
                      Advanced Catch Reports
                    </div>
                    <div className='fs-18 gSemiBold grey-800 mg-bottom-15'>
                      <img
                        src={imagePaths.checked}
                        alt='checked'
                        className='mg-right-15'
                      />
                      Live Availability
                    </div>
                  </div>
                </div>
                <div className='QR'>
                  <img src={imagePaths.appQR} alt='app-qr' />
                </div>
                <div className='app-mobile-icons'>
                  <a
                    href={
                      'https://apps.apple.com/gb/app/swimbooker/id1586855906'
                    }
                    className={'sb-footer__app_link'}
                  >
                    <img
                      src={imagePaths.appStoreIcon}
                      alt={'Download on the App Store'}
                    />
                  </a>
                  <a
                    className={'sb-footer__app_link'}
                    href='https://play.google.com/store/apps/details?id=com.app.swimbookerapp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'
                  >
                    <img
                      src={imagePaths.googlePlayIcon}
                      alt='Get it on Google Play'
                    />
                  </a>
                </div>
              </div>
              <div className='gSemiBold fs-20 grey-900 text'>
                Scan the QR code with your device camera and download our app
              </div>
            </div>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default HomePageVenueContent;
