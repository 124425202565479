import '../assets/css/member-sign-up-layout.css';
import logo from '../../../assets/images/Logos/Large_swimbooker_logo_file_with_backdrop.png';

function MemberSignUpLayout({ children }) {
  return (
    <div className='member-sign-up__layout sb-blue-bg-100'>
      <img className='member-sign-up__logo' src={logo} alt='Swimbooker' />
      <div className='member-sign-up__inner'>{children}</div>
    </div>
  );
}

export default MemberSignUpLayout;
