import React from 'react';
import { Input, Col, Radio, Row, Select, AutoComplete } from 'antd';
import { useGlobalState } from './GlobalState';
import { useState } from 'react';
function Step2({ focus }) {
  const [catchInfo, setCatchInfo] = useGlobalState('catchInfo');
  const [validating, setValidating] = useGlobalState('validating');

  return (
    <React.Fragment>
      <Input
        placeholder='Hooklink Material (optional)'
        value={catchInfo.hookLink}
        autoFocus={focus.hookLink}
        onChange={(e) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            hookLink: e.target.value,
          }));
        }}
        size='large'
        className='br5 gRegular w-full mg-top-15'
      />
      <Input
        placeholder='Rig (optional)'
        value={catchInfo.rig}
        autoFocus={focus.rig}
        onChange={(e) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            rig: e.target.value,
          }));
        }}
        size='large'
        className='br5 gRegular w-full mg-top-15'
      />
      <Input
        placeholder='Hook (optional)'
        value={catchInfo.hook}
        autoFocus={focus.hook}
        onChange={(e) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            hook: e.target.value,
          }));
        }}
        size='large'
        className='br5 gRegular w-full mg-top-15'
      />
      <Input
        placeholder='Bait (optional)'
        size='large'
        value={catchInfo.bait}
        autoFocus={focus.bait}
        onChange={(e) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            bait: e.target.value,
          }));
        }}
        className='br5 gRegular w-full mg-top-15'
      ></Input>
      <Input.TextArea
        style={{ height: '100px', fontSize: 16 }}
        placeholder={'Baiting Tactics (optional)'}
        value={catchInfo.baitTactic}
        onChange={(e) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            baitTactic: e.target.value,
          }));
        }}
        className='mg-top-15 gRegular'
      />
      <Col span={24}>
        <Row justify={'space-between'} className='mg-top-15 w-full'>
          <Col
            span={24}
            className='gLight  mg-bottom-10 '
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Is this a named fish?
          </Col>
          <Col span={24}>
            <Radio.Group
              value={catchInfo.isNamedFish}
              onChange={(e) => {
                setCatchInfo((prevState) => ({
                  ...prevState,
                  isNamedFish: e.target.value,
                }));
              }}
              size='large'
              className='fr row-center w-full'
              id='PB-button'
            >
              <Radio.Button
                value={true}
                className='gRegular txt-center'
                style={{ padding: '0 2rem', flex: 1 }}
              >
                Yes
              </Radio.Button>
              <Radio.Button
                value={false}
                className='gRegular txt-center'
                style={{ flex: 1 }}
              >
                {`  No  `}
              </Radio.Button>
              <Radio.Button
                value={'not sure'}
                className='gRegular txt-center'
                style={{ padding: '0 2rem', flex: 1 }}
              >
                {`  Not Sure  `}
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </Col>
      {catchInfo?.isNamedFish && (
        <Input
          placeholder='Fish Name (optional)'
          size='large'
          value={catchInfo.fishName}
          autoFocus={focus.fishName}
          onChange={(e) => {
            setCatchInfo((prevState) => ({
              ...prevState,
              fishName: e.target.value,
            }));
          }}
          className='br5 gRegular w-full mg-top-15'
        />
      )}

      <Input.TextArea
        style={{ height: '120px', fontSize: 16 }}
        placeholder='Additional Capture Notes A few ideas: Wraps to spot, Features fished to, Depth of spot (optional)'
        value={catchInfo.captureNote}
        onChange={(e) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            captureNote: e.target.value,
          }));
        }}
        className='mg-top-15 gRegular'
        key={`note`}
      />
      <Input.TextArea
        style={{ height: '120px', fontSize: 16 }}
        placeholder='Additional Fish Notes A few ideas: Any notable scale patterns, any scars or damage to the fish, is it the biggest fish in the lake? (optional)'
        value={catchInfo.fishNote}
        onChange={(e) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            fishNote: e.target.value,
          }));
        }}
        className='mg-top-15 gRegular'
      />
    </React.Fragment>
  );
}

export default Step2;
