import React from 'react';
import { Layout } from 'antd';

// CSS
const { Content } = Layout;

class CustomAdminLoginLayout extends React.Component {
  render() {
    return <Content>{this.props.children}</Content>;
  }
}

export default CustomAdminLoginLayout;
