import * as React from "react"
const Hourglass = ({ width=512, height=512, ...props }) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={'0 0 512 512'} {...props}>
    <path
      fill="#d8ecfe"
      d="M385.999 74.604V36.629l-259.998-.002v37.975c0 59.979 28.879 116.799 78.581 153.279 8.971 6.585 14.33 16.997 14.33 28.125 0 11.106-5.326 21.518-14.281 28.087-50.767 37.242-78.633 94.554-78.633 153.304v37.976l259.998.002V437.4c0-58.66-27.788-116.003-78.636-153.307-8.953-6.568-14.275-16.979-14.275-28.083v-.001c0-11.131 5.362-21.543 14.335-28.129 49.59-36.401 78.579-93.165 78.579-153.276z"
    />
    <path
      fill="#c4e2ff"
      d="M307.421 227.878c49.59-36.399 78.578-93.163 78.579-153.275V36.628l-259.998-.001 217.479.001v37.975c0 60.112-28.989 116.876-78.579 153.275-8.973 6.586-14.335 16.998-14.335 28.129v.001c0 11.104 5.323 21.515 14.275 28.083 50.849 37.304 78.637 94.647 78.636 153.307v37.975h42.52v-37.975c0-58.66-27.788-116.003-78.636-153.307-8.953-6.568-14.276-16.979-14.275-28.083v-.001c-.001-11.131 5.361-21.543 14.334-28.129z"
    />
    <path
      fill="#ffd15b"
      d="M155.999 444.851V439.8c0-25.113 12.988-48.88 36.137-67.931 18.029-14.838 40.671-22.653 63.861-22.653 23.19 0 45.832 7.816 63.862 22.654 23.15 19.053 36.138 42.819 36.138 67.931v5.051z"
    />
    <path
      fill="#596c76"
      d="M119.001 36.627H393c9.941 0 18-8.199 18-18.313C411 8.2 402.941.001 393 .001H119.001c-9.942 0-18.001 8.2-18 18.315.001 10.113 8.059 18.311 18 18.311zM119.001 512H393c9.941 0 18-8.199 18-18.313 0-10.114-8.059-18.313-18-18.313H119.001c-9.942 0-18.001 8.2-18 18.315.001 10.113 8.059 18.311 18 18.311z"
    />
    <path
      fill="#feb137"
      d="M255.998 330.173a7.726 7.726 0 0 1-7.726-7.726v-10.302a7.726 7.726 0 1 1 15.452 0v10.302a7.726 7.726 0 0 1-7.726 7.726zm0-40.177a7.726 7.726 0 0 1-7.726-7.726v-10.302a7.726 7.726 0 1 1 15.452 0v10.302a7.726 7.726 0 0 1-7.726 7.726zm0-40.177a7.726 7.726 0 0 1-7.726-7.726v-10.302a7.726 7.726 0 1 1 15.452 0v10.302a7.726 7.726 0 0 1-7.726 7.726zM157.742 97.956c4.958 33.021 20.345 63.479 43.98 87.534 14.38 14.636 33.94 22.783 54.28 22.783s39.899-8.147 54.279-22.782c23.634-24.053 39.021-54.512 43.979-87.535z"
    />
    <path
      fill="#465a61"
      d="M392.999 0h-52.564c9.941 0 18 8.199 18 18.313s-8.059 18.313-18 18.313h52.564c9.941 0 18-8.199 18-18.313S402.94 0 392.999 0zM392.999 475.373h-52.564c9.941 0 18 8.199 18 18.313 0 10.114-8.059 18.313-18 18.313h52.564c9.941 0 18-8.199 18-18.313 0-10.114-8.059-18.313-18-18.313z"
    />
    <path
      fill="#ffc344"
      d="M337.161 389.308a186.85 186.85 0 0 1 6.317 48.091v7.453h12.52V439.8c-.001-17.926-6.628-35.163-18.837-50.492z"
    />
    <path
      fill="#f6ab31"
      d="M342.001 97.957c-4.414 35.001-18.659 68.034-40.985 95.544a76.232 76.232 0 0 0 9.264-8.008c23.634-24.053 39.021-54.512 43.979-87.535h-12.258z"
    />
  </svg>
)
export default Hourglass
