import React from 'react';
import { Button, Col, Divider, Form, Input, Row, Spin } from 'antd';
import { useLogin } from '../../utils/hooks';

export default function UserLoginForm({
  useMobileButtonStyle = false,
  hideCreateAccount = false,
  className = '',
  style = {},
  noReload = false,
}) {
  const [login, loading] = useLogin();

  return (
    <>
      <Row className={className} style={style}>
        <Col span={24} className='login-menu-items'>
          <Form
            name='basic'
            onFinish={(values) =>
              login({
                username: values?.username,
                password: values?.password,
              }).then(() => {
                if (!noReload) {
                  setTimeout(() => {
                    window.location.reload(true);
                  }, 500);
                }
              })
            }
            onFinishFailed={() => {}}
          >
            <p />
            <Form.Item
              name='username'
              className='gLight mg-bottom-15'
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input
                placeholder='Email'
                className='br5 email mg-left-0 angler-login-email-field gLight'
              />
            </Form.Item>

            <Form.Item
              name='password'
              className='gLight mg-bottom-15'
              rules={[
                {
                  required: true,
                  message: 'Please input your password!',
                },
              ]}
            >
              <Input.Password
                placeholder='Password'
                className='br5 angler-login-password-field gLight'
              />
            </Form.Item>

            <Form.Item>
              {useMobileButtonStyle ? (
                <Button
                  className='mobile-login-btn'
                  type='primary'
                  htmlType='submit'
                >
                  {loading? <Spin/> : 'Log In'}
                </Button>
              ) : (
                <Button
                  className='admin-login-btn light-green-bg gBold'
                  type='primary'
                  htmlType='submit'
                >
                  {loading? <Spin/> : 'SIGN IN'}
                </Button>
              )}
            </Form.Item>
          </Form>

          <p className='lh-1 mg-top-20'>
            <p className='lh-1 txt-center gLight'>
              <a href='/user/forgot_password'>Forgot your password?</a>
            </p>
            {!hideCreateAccount && (
              <>
                <Divider />
                <p className='lh-1 gSemiBold txt-center login-menu-items__sub-heading'>
                  Don't have an account?
                </p>
                <p className='lh-1 gLight txt-center'>
                  <a href='/user/signup'>Create one here (It's FREE!)</a>
                </p>
              </>
            )}
          </p>
        </Col>
      </Row>

      <Row>
        <Col span={24} />
      </Row>
    </>
  );
}
