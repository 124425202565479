import { Button, Col, Row } from 'antd';
import ArrowLeft from '../../../../admin/assets/images/lake_manager/LeftArrow.png';
import ArrowRight from '../../../../admin/assets/images/lake_manager/RightArrow.png';
import rightArrow from '../../../assets/images/rightArrow.svg';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import '../assets/css/sliders.css';
import { Swiper } from 'swiper/react';
import { Navigation } from 'swiper';
import 'swiper/swiper.min.css';

const ButtonGroup = ({
  onChange,
  onStateChange,
  infinite = false,
  numSlides,
  next,
  previous,
  rightIcon,
  leftIcon,
  ...rest
}) => {
  const { carouselState } = rest;
  const { currentSlide } = carouselState;

  useEffect(() => {
    onStateChange(carouselState);
  }, [carouselState, onStateChange]);

  useEffect(() => {
    onChange((currentSlide + numSlides - 2) % numSlides);
  }, [onChange, currentSlide, numSlides, carouselState]);

  return (
    <div className='carousel-button-group'>
      {leftIcon && !(numSlides <= 1 || (!infinite && currentSlide === 0)) ? (
        <img
          src={leftIcon}
          alt='left arrow'
          width={31}
          height={30}
          className='cp'
          onClick={() => previous()}
        />
      ) : (
        <Button
          className='carousel-btn carousel-prev'
          disabled={numSlides <= 1 || (!infinite && currentSlide === 0)}
          onClick={() => previous()}
        >
          <img src={ArrowLeft} alt='Left arrow' />
        </Button>
      )}
      {rightIcon &&
      !(numSlides <= 1 || (!infinite && currentSlide === numSlides - 1)) ? (
        <img
          src={rightIcon}
          alt='Right arrow'
          width={31}
          height={30}
          className='cp'
          onClick={() => next()}
        />
      ) : (
        <Button
          className='carousel-btn carousel-next'
          disabled={
            numSlides <= 1 || (!infinite && currentSlide === numSlides - 1)
          }
          onClick={() => next()}
        >
          <img src={ArrowRight} alt='Right arrow' />
        </Button>
      )}
    </div>
  );
};
const ButtonGroupSwiper = ({
  onChange,
  onStateChange,
  infinite = false,
  numSlides,
  next,
  previous,
  rightIcon,
  leftIcon,
  ...rest
}) => {
  return (
    <div className='carousel-button-group'>
      {leftIcon ? (
        <img
          src={leftIcon}
          alt='left arrow'
          width={31}
          height={30}
          className='cp'
          onClick={() => {}}
        />
      ) : (
        <Button className='carousel-btn carousel-prev' onClick={() => {}}>
          <img src={ArrowLeft} alt='Left arrow' />
        </Button>
      )}
      {rightIcon ? (
        <img
          src={rightIcon}
          alt='Right arrow'
          width={31}
          height={30}
          className='cp'
          onClick={() => {}}
        />
      ) : (
        <Button className='carousel-btn carousel-next' onClick={() => {}}>
          <img src={ArrowRight} alt='Right arrow' />
        </Button>
      )}
    </div>
  );
};

const verticalBreakpoints = {
  largeDesktop: {
    breakpoint: { max: 10000, min: 1100 },
    items: 4,
    slidesToSlide: 3,
    partialVisibilityGutter: 20,
  },
  desktop: {
    breakpoint: { max: 1100, min: 900 },
    items: 3,
    slidesToSlide: 2,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: { max: 900, min: 464 },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 464, min: 200 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 60,
  },
  smallMobile: {
    breakpoint: { max: 200, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
};

const horizontalBreakpoints = {
  desktop: {
    breakpoint: { max: 10000, min: 1200 },
    items: 3,
    slidesToSlide: 2,
    partialVisibilityGutter: 30,
  },
  tablet: {
    breakpoint: { max: 1200, min: 600 },
    items: 2,
    slidesToSlide: 2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 600, min: 300 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 60,
  },
  smallMobile: {
    breakpoint: { max: 300, min: 0 },
    items: 1,
    slidesToSlide: 1,
    partialVisibilityGutter: 0,
  },
};

/**
 * Base responsive card slider using react-multi-carousel
 * @param children {Array<JSX.Element>} The slide elements to be rendered
 * @param className {string | null} (optional) classes to apply to the slider
 * @param fullWidth {boolean} (optional) Whether the slider always shows 1 full-width card (non-responsive number of cards). Should not be specified along with breakpointsOverride
 * @param noHoverAnimation {boolean} (optional) Whether to disable the on hover/click user feedback animation
 * @param horizontal {boolean} (optional) Whether the slider user horizontal (wide) cards or vertical (tall) cards
 * @param hideButtons {boolean} (optional) Whether to hide the left-right buttons
 * @param hideButtonsOnMobile {boolean} (optional) Whether to hide the left-right buttons on mobile devices (recommended if fullWidth=true)
 * @param infinite {boolean} (optional) Whether the slider repeats (is an infinite loop) -- default: true
 * @param showPartialNext {boolean} (optional) Whether to show a partial preview of the next card (often used alongside hideButtons)
 * @param extra {string | null} (optional) The text to show below the slider (default: 'Swipe for more ...')
 * @param extraClassName {string | null} (optional) classes to apply to the extra text
 * @param breakpointsOverride {
 *     [key: string]: {
 *         breakpoint: {
 *             max: number;
 *             min: number;
 *         };
 *         items: number;
 *         partialVisibilityGutter?: number;
 *         slidesToSlide?: number;
 *     } | null} (optional) Allows specification of breakpoints different from the default. Should not be specified along with fullWidth
 * @param onChange {(currentIndex: number) => void} (optional) A function to carry out when the slider changes (index may be inaccurate when infinite=true)
 * @returns {JSX.Element}
 */
export default function BaseSlider({
  children,
  fullWidth = false,
  noHoverAnimation = false,
  horizontal = false,
  hideButtons = false,
  hideButtonsOnMobile = false,
  infinite = true,
  showPartialNext = false,
  extra = 'Swipe for more ...',
  extraClassName = '',
  isSwiperJs = false,
  swiperSpaceBetween = 30,
  className = '',
  ButtonLeftIcon,
  ButtonRightIcon,
  breakpointsOverride = null,
  onChange = (currentIndex) => {},
}) {
  const touchStartPos = useRef(null);
  const verticalScrollDisabled = useRef(false);
  const [areButtonsUnnecessary, setAreButtonsUnnecessary] = useState(
    children.length <= 1
  );

  const onStateChange = useCallback(
    (carouselState) => {
      if (!carouselState) {
        return;
      }
      const { slidesToShow } = carouselState;
      setAreButtonsUnnecessary(children.length <= slidesToShow);
    },
    [children.length]
  );

  let breakpoints = breakpointsOverride;
  if (!breakpoints) {
    if (fullWidth) {
      breakpoints = {
        singleItem: {
          breakpoint: { max: 10000, min: 0 },
          items: 1,
          slidesToSlide: 1,
          partialVisibilityGutter: 0,
        },
      };
    } else if (horizontal) {
      breakpoints = horizontalBreakpoints;
    } else {
      breakpoints = verticalBreakpoints;
    }
  }

  const shouldShowPartialNext = showPartialNext && !areButtonsUnnecessary;

  return (
    <>
      <Row
        className={
          'slider-wrapper ' +
          (horizontal ? 'horizontal ' : 'vertical ') +
          (fullWidth ? 'fullWidth ' : '') +
          (shouldShowPartialNext ? 'partialNext ' : '') +
          (hideButtons || areButtonsUnnecessary ? 'hideButtons ' : '') +
          (hideButtonsOnMobile ? 'hideButtonsOnMobile ' : '') +
          (noHoverAnimation ? 'noHover ' : '') +
          className
        }
      >
        <Col className='gutter-row slider br20' span={24}>
          <div
            onTouchStart={(e) =>
              (touchStartPos.current = {
                x: e.touches[0].clientX,
                y: e.touches[0].clientY,
              })
            }
            onTouchMove={(e) => {
              const touchEndPos = {
                x: e.changedTouches[0].clientX,
                y: e.changedTouches[0].clientY,
              };
              const deltaX = Math.abs(
                touchEndPos.x - touchStartPos?.current?.x
              );
              const deltaY = Math.abs(
                touchEndPos.y - touchStartPos?.current?.y
              );
              if (
                !verticalScrollDisabled.current &&
                deltaX > 10 &&
                deltaX / deltaY > 0.75
              ) {
                // Uses setTimeout to push code into a different execution flow (run asynchronously) to help
                // prevent jittery slider movement
                setTimeout(() => {
                  document.getElementsByTagName('html')[0].style.overflow =
                    'hidden';
                }, 0);
                verticalScrollDisabled.current = true;
              }
            }}
            onTouchEnd={() => {
              document.getElementsByTagName('html')[0].style.overflow =
                'initial';
              verticalScrollDisabled.current = false;
            }}
          >
            {isSwiperJs ? (
              <>
                <div className='swiper-button-wrapper'>
                  <ButtonGroupSwiper
                    rightIcon={ButtonRightIcon}
                    leftIcon={ButtonLeftIcon}
                  />
                </div>
                <Swiper
                  grabCursor={true}
                  autoplay={false}
                  modules={[Navigation]}
                  spaceBetween={swiperSpaceBetween}
                  breakpoints={
                    breakpointsOverride
                      ? breakpointsOverride
                      : {
                          640: {
                            slidesPerView: 1,
                          },
                          768: {
                            slidesPerView: 2,
                          },
                          1024: {
                            slidesPerView: 3,
                          },
                        }
                  }
                  navigation={{
                    nextEl: '.carousel-next',
                    prevEl: '.carousel-prev',
                  }}
                >
                  {children}
                </Swiper>
              </>
            ) : (
              <Carousel
                swipeable={!areButtonsUnnecessary}
                responsive={breakpoints}
                infinite={infinite}
                customButtonGroup={
                  <ButtonGroup
                    infinite={infinite}
                    numSlides={children.length}
                    onChange={onChange}
                    onStateChange={
                      onStateChange /* Used as a way to access the carouselState outside useRef */
                    }
                    rightIcon={ButtonRightIcon}
                    leftIcon={ButtonLeftIcon}
                  />
                }
                renderButtonGroupOutside={true}
                rewindWithAnimation={true}
                partialVisible={shouldShowPartialNext}
                arrows={false}
              >
                {children}
              </Carousel>
            )}
          </div>
        </Col>

        {extra && (
          <Col
            xs={24}
            sm={0}
            className={'txt-center gLight fs-14 slider-extra ' + extraClassName}
          >
            {extra}
          </Col>
        )}
      </Row>
    </>
  );
}
