import { Menu } from 'antd';
import { Link } from 'react-router-dom';
import React from 'react';
import { parseIcon } from './menuOptions';

export function VerticalMenuItem({ favourMobileIcon = false, ...menuItem }) {
  return (
    <Menu.Item>
      <Link
        className='gSemiBold row-center'
        to={menuItem?.link || '#'}
        onClick={menuItem?.onClick}
      >
        {parseIcon(
          (favourMobileIcon ? menuItem?.mobileIcon : undefined) ||
            menuItem?.icon
        )}
        {menuItem.title}
      </Link>
    </Menu.Item>
  );
}
