import React from 'react';
import '../assets/css/widget-loading.css';
import LoadingSpinner from '../../common/LoadingSpinner';

export default function WidgetLoading() {
  return (
    <div className='loadingContainer'>
      <LoadingSpinner className='mg-auto-lr' />
    </div>
  );
}
