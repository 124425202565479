import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { notification, Modal, Spin, Upload } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useGlobalState } from './GlobalState';

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

function UploadImages(props) {
  const [fileList, setFileList] = useGlobalState('fileList');
  const [filePreviews, setFilePreviews] = useGlobalState('filePreview');

  const image_data = {
    uid: props.id,
    name: props.id,
    status: 'done',
    url: props.image,
  };

  const [uploading, setUploading] = useState(false);
  const [preview, setPreview] = useState({
    visible: false,
    image: '',
    title: '',
  });

  let validateType = require('../../../../common/Utils').validateType;
  let validateSize = require('../../../../common/Utils').validateSize;

  const beforeUpload = (file) => {
    if (validateType(file)) {
      if (validateSize(file)) {
        setFilePreviews([file]);

        return false;
      } else {
        setFileList('');
        setFilePreviews([]);
        notification.error({
          message: <div className='gSemiBold'>There was an issue!</div>,
          style: { border: '1px #D34545 solid' },
          placement: window?.innerWidth <= 500 ? 'bottom' : 'topRight',
          className: 'gRegular br10',
          description: 'Image must smaller than 10MB!',
        });
      }
    } else {
      setFileList('');
      setFilePreviews([]);
      notification.error({
        message: <div className='gSemiBold'>There was an issue!</div>,
        style: { border: '1px #D34545 solid' },
        placement: window?.innerWidth <= 500 ? 'bottom' : 'topRight',
        className: 'gRegular br10',
        description: 'You can only upload JPG/PNG file!',
      });
    }
  };

  const handleCancel = () =>
    setPreview((preview) => ({ ...preview, visible: false }));

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      //   file.preview = await getBase64(file.originFileObj);
    }
    setPreview({
      image: file.url || file.preview,
      visible: true,
      title: file.name || file.url.substring(file.url.lastIndexOf('/') + 1),
    });
    console.log(file);
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div className='gRegular' style={{ marginTop: 8 }}>
        Upload Photo
      </div>
    </div>
  );

  const postFiles = (body) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
        withCredentials: true,
        crossDomain: true,
        baseURL: `${process.env.REACT_APP_AXIOS_URL}fishery`,
        'Access-Control-Allow-Origin': '*',
        sameSite: null,
        secure: false,
      };
      return axios
        .post('/catch/report/image', body, config)
        .then((res) => res)
        .catch((err) => err);
    } catch (err) {
      return [];
    }
  };

  const deleteFile = (body) => {
    try {
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
        withCredentials: true,
        crossDomain: true,
        // baseURL: `${process.env.REACT_APP_AXIOS_URL}fishery`,
        'Access-Control-Allow-Origin': '*',
        sameSite: null,
        secure: false,
        data: body,
      };

      return axios.delete('/image', config).then((res) => res);
    } catch (err) {
      return [];
    }
  };

  const uploadImageToServer = (info) => {
    if (validateType(info.file) && validateSize(info.file)) {
      setFilePreviews(info.fileList);

      const formData = new FormData();

      formData.append('file', info.file);

      postFiles(formData)
        .then((res) => {
          setFileList(res.data.image_path);
          setUploading(false);
          if (res.data.status === 'Image uploaded') {
            notification.success({
              message: <div className='gSemiBold'>Success!</div>,
              style: { border: '1px #2CB972 solid' },
              placement: window?.innerWidth <= 500 ? 'bottom' : 'topRight',
              className: 'gRegular br10',
              description: `Uploaded Image Successfully`,
            });
          } else {
            notification.error({
              message: <div className='gSemiBold'>There was an issue!</div>,
              style: { border: '1px #D34545 solid' },
              placement: window?.innerWidth <= 500 ? 'bottom' : 'topRight',
              className: 'gRegular br10',
              description: `Failed to upload ${info.file.name}.`,
            });
          }
        })
        .catch(() => {
          notification.error({
            message: <div className='gSemiBold'>There was an issue!</div>,
            style: { border: '1px #D34545 solid' },
            placement: window?.innerWidth <= 500 ? 'bottom' : 'topRight',
            className: 'gRegular br10',
            description: `Failed to upload ${info.file.name}.`,
          });
          setUploading(false);
        });
    }
  };

  const onRemove = (file) => {
    setFileList('');
    setFilePreviews((curList) => {
      const index = curList?.indexOf(file);
      const newFileList = curList.slice();
      newFileList.splice(index, 1);
      return newFileList;
    });
  };

  const deleteImageFromServer = (info) => {
    setFilePreviews(info.fileList);

    const formData = new FormData();
    formData.append('id', props.id);
    formData.append('image_index', props.image_index);
    formData.append('type', props.type);

    deleteFile(formData)
      .then((res) => {
        if (res.status === 200) {
          notification.success({
            message: <div className='gSemiBold'>Success!</div>,
            style: { border: '1px #2CB972 solid' },
            placement: window?.innerWidth <= 500 ? 'bottom' : 'topRight',
            className: 'gRegular br10',
            description: `Image removed successfully`,
          });
        }
      })
      .catch(() => {
        if (info.file.status === 'error') {
          notification.error({
            message: <div className='gSemiBold'>There was an issue!</div>,
            style: { border: '1px #D34545 solid' },
            placement: window?.innerWidth <= 500 ? 'bottom' : 'topRight',
            className: 'gRegular br10',
            description: `Delete failed.`,
          });
        }
      });
  };

  const uploadProps = {
    name: 'avatar',
    listType: 'picture-card',
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.fileList.length > 0) {
        setUploading(true);
        uploadImageToServer(info);
      } else if (info.fileList.length <= 0) {
        // deleteImageFromServer(info);
      } else {
        setFilePreviews(info.fileList);
      }
    },
  };

  return (
    <>
      {/* {uploading ? (
        <div
          className={`${props.type} fishery-manager-admin file-upload-loading`}
        >
          <Spin />
        </div>
      ) : (
        ''
      )} */}
      <Upload
        {...uploadProps}
        beforeUpload={beforeUpload}
        onRemove={onRemove}
        onPreview={handlePreview}
        className='catch-report-upload'
        fileList={filePreviews}
      >
        {fileList ? null : uploadButton}
      </Upload>
    </>
  );
}

export default withRouter(UploadImages);
