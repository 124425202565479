import { configureStore } from '@reduxjs/toolkit';
import userReducer from './User/Reducer';
import menuReducer from './Menu/Reducer';
import adminReducer from './Admin/Reducer';
import { apiSlice } from './apiSlice';
import { proApiSlice } from './proApiSlice';

export const setupStore = (preloadedState = {}) =>
  configureStore({
    reducer: {
      User: userReducer,
      Menu: menuReducer,
      Admin: adminReducer,
      [apiSlice.reducerPath]: apiSlice.reducer,
      [proApiSlice.reducerPath]: proApiSlice.reducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware()
        .concat(apiSlice.middleware)
        .concat(proApiSlice.middleware),
    preloadedState,
  });
