import { Input, Select, AutoComplete, Checkbox, Col, Radio, Row } from 'antd';
import React, { useMemo, useEffect } from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useSelector } from 'react-redux';
import { debounce } from 'lodash';
import { useGlobalState } from './GlobalState';
import axios from 'axios';
import { useState } from 'react';

function Step1({ focus, date, time, setDate, setTime, isOpen }) {
  const [catchInfo, setCatchInfo] = useGlobalState('catchInfo');
  const [fisheryOption, setFisheryOption] = useGlobalState('fisheryOption');
  const [lakeOption, setLakeOption] = useGlobalState('lakeOption');
  const [anglerName, setAnglerName] = useGlobalState('anglerName');
  const [loading, setLoading] = useState(false);
  const [dontHaveName, setDontHaveName] = useGlobalState('dontHaveName');
  const [validating, setValidating] = useGlobalState('validating');
  const { isRequestFromProfile, isRequestFromAdmin } = useSelector(
    (state) => state.Menu
  );
  const sessionOption = [
    'Day Session',
    'Night Session',
    '24hrs',
    '36hrs',
    '48hrs',
    '60hrs',
    '72hrs',
    '84hrs',
    '96hrs',
    '108hrs',
    '120hrs+',
  ];
  const [SpeciesOption, setSpeciesOption] = useState([
    'Common Carp',
    'Mirror Carp',
    'Grass Carp',
    'Leather Carp',
    'Ghost Carp',
    'Crucian Carp',
    'Koi Carp',
    'Catfish',
    'Bream',
    'Tench',
    'Roach',
    'Rudd',
    'Perch',
    'Dace',
    'Brown Trout',
    'Rainbow Trout',
    'Pumpkinseed',
    'Eel',
    'Pike',
    'Chub',
    'Sturgeon',
    'Barbel',
    'Grayling',
    'Goldfish',
    'Orph',
  ]);

  const getFisheries = (value) => {
    // setLoading(true);

    const axios_config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      params: { search: value },
    };

    axios
      .get('fishery/fisheries', axios_config)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.result;
          setFisheryOption(
            data.hits.map((v, i) => {
              return {
                key: i,
                label: v.name ? <span className='gLight'>{v.name}</span> : '',
                value: [v?.name, v?.id, v?.public_id],
              };
            })
          );
        }
      })
      .catch((err) => {})
      .finally(() => {
        // setLoading(false);
      });
  };
  const getLake = (value) => {
    // setLoading(true);

    const axios_config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      withCredentials: true,
      params: { id: value },
    };

    axios
      .get('fishery', axios_config)
      .then((res) => {
        if (res.status === 200) {
          let data = res.data.result;
          setLakeOption(
            data.lakes.map((lake, i) => {
              return {
                key: i,
                label: lake.name ? (
                  <span className='gRegular'>{lake.name}</span>
                ) : (
                  ''
                ),
                value: lake?.name,
              };
            })
          );
        }
      })
      .catch((err) => {})
      .finally(() => {
        // setLoading(false);
      });
  };
  const debouceSearch = useMemo(
    () =>
      debounce((value) => {
        getFisheries(value);
      }, 800),
    []
  );
  const handleFisheryChange = (value) => {
    setCatchInfo((prevState) => ({
      ...prevState,
      fisheryName: value,
      fisheryId: '',
      fisheryPublicId: '',
      lakeDisable: true,
    }));
    setLakeOption([]);

    debouceSearch(value);
  };

  return (
    <React.Fragment>
      <AutoComplete
        placeholder='Enter Fishery Name'
        disabled={isRequestFromAdmin}
        value={catchInfo.fisheryName}
        autoFocus={focus.fishery}
        options={fisheryOption}
        onSelect={(value) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            fisheryName: value[0],
            fisheryId: value[1],
            fisheryPublicId: value[2],
            lakeDisable: false,
            lake: null,
          }));
          getLake(value[2]);
        }}
        status={validating && !catchInfo.fisheryName ? 'error' : ''}
        size='large'
        className='br5 gRegular w-full mg-top-15'
        onChange={(v) => {
          handleFisheryChange(v);
        }}
      />
      {isRequestFromAdmin && (
        <>
          <Input
            placeholder='Angler Name'
            value={anglerName}
            disabled={dontHaveName}
            autoFocus={focus.swim}
            onChange={(e) => {
              setAnglerName(e.target.value);
            }}
            size='large'
            className='br5 gRegular w-full mg-top-15'
          ></Input>
          <div className='center'>
            <Checkbox
              className='picture-checkbox gLight mg-top-15'
              checked={dontHaveName}
              onClick={() => {
                setDontHaveName((prev) => !prev);
                setAnglerName('');
              }}
            >
              I don't have angler name
            </Checkbox>
          </div>
        </>
      )}

      <AutoComplete
        placeholder='Select Lake'
        value={catchInfo.lake}
        autoFocus={focus.lake}
        options={lakeOption}
        onSelect={(value) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            lake: value,
          }));
        }}
        status={validating && !catchInfo.lake ? 'error' : ''}
        size='large'
        className='br5 gRegular w-full mg-top-15'
        onChange={(value) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            lake: value,
          }));
        }}
      />
      <Input
        placeholder='Select Swim (optional)'
        value={catchInfo.swim}
        autoFocus={focus.swim}
        onChange={(e) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            swim: e.target.value,
          }));
        }}
        size='large'
        className='br5 gRegular w-full mg-top-15'
      />

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label='Select Date'
          //   size='large'
          disableFuture={true}
          autoFocus={focus.date}
          value={date}
          style={{ fontFamily: 'gRegular' }}
          onChange={(value) => {
            if (value) {
              setDate(value);
            }
          }}
          slotProps={{
            textField: { size: 'small', error: validating && !date },
          }}
          className='br5 gRegular w-full mg-top-15 '
          id='gRegular'
          format={'dddd D  MMMM YYYY'}
        ></DatePicker>
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <TimePicker
          label='Select time'
          size='large'
          autoFocus={focus.time}
          slotProps={{ textField: { size: 'small' } }}
          value={time}
          onChange={(value) => {
            setTime(value);
          }}
          className='br5 gRegular w-full mg-top-15'
          format={'HH:mm'}
        ></TimePicker>
      </LocalizationProvider>
      <Select
        placeholder='Length of Session (optional)'
        options={sessionOption?.map((v) => {
          return {
            value: v,
            label: <span className='gRegular'>{v}</span>,
          };
        })}
        value={catchInfo?.length_of_session}
        onChange={(e) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            length_of_session: e,
          }));
        }}
        size='large'
        className='br5 gRegular w-full mg-top-15'
      ></Select>

      <AutoComplete
        placeholder='Fish Species'
        status={validating && !catchInfo.species ? 'error' : ''}
        value={catchInfo.speciesValue}
        size='large'
        autoFocus={focus.species}
        options={SpeciesOption.map((item) => ({
          value: item,
          label: item,
        }))}
        filterOption={true}
        onSelect={(value) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            species: value,
            speciesValue: value,
          }));
        }}
        className='br5 gRegular w-full mg-top-15'
        onChange={(value) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            species: '',
            speciesValue: value,
          }));
        }}
      />
      <Input
        placeholder='Fish Weight (lbs)'
        type='number'
        status={validating && !catchInfo.fish_weight_lb ? 'error' : ''}
        value={catchInfo.fish_weight_lb}
        onChange={(e) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            fish_weight_lb: e.target.value,
          }));
        }}
        size='large'
        className='br5 gRegular w-full mg-top-15'
      />
      <Input
        type='number'
        max={16}
        placeholder='Fish Weight (oz)'
        status={validating && !catchInfo.fish_weight_oz ? 'error' : ''}
        value={catchInfo.fish_weight_oz}
        onChange={(e) => {
          setCatchInfo((prevState) => ({
            ...prevState,
            fish_weight_oz:
              e.target.value <= 16 && e.target.value >= 0 && e.target.value,
          }));
        }}
        size='large'
        className='br5 gRegular w-full mg-top-15'
      />
      <Col span={24}>
        <Row justify={'space-between'} className='mg-top-15 w-full'>
          <Col
            span={11}
            className='gLight'
            style={{ display: 'flex', alignItems: 'center' }}
          >
            Is this your new PB for this species?
          </Col>
          <Col span={12}>
            <Radio.Group
              value={catchInfo.personalBest}
              onChange={(e) => {
                setCatchInfo((prevState) => ({
                  ...prevState,
                  personalBest: e.target.value,
                }));
              }}
              size='large'
              className='fr'
              id='PB-button'
            >
              <Radio.Button
                value={true}
                className='gRegular'
                style={{ padding: '0 2rem' }}
              >
                {`  Yes!  `}
              </Radio.Button>
              <Radio.Button
                value={false}
                className='gRegular'
                style={{ padding: '0 2rem' }}
              >
                {`  No  `}
              </Radio.Button>
            </Radio.Group>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
}

export default Step1;
